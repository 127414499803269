/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const activateUser = /* GraphQL */ `
  mutation ActivateUser($input: ActivateUserInput) {
    ActivateUser(input: $input)
  }
`;
export const activateImpersonateUser = /* GraphQL */ `
  mutation ActivateImpersonateUser($input: ActivateImpersonateUserInput) {
    ActivateImpersonateUser(input: $input)
  }
`;
export const uploadSurveyReportAndComplete = /* GraphQL */ `
  mutation UploadSurveyReportAndComplete(
    $input: UploadSurveyReportAndCompleteInput
  ) {
    UploadSurveyReportAndComplete(input: $input)
  }
`;
export const editMyProfile = /* GraphQL */ `
  mutation EditMyProfile($input: EditMyProfileInput) {
    EditMyProfile(input: $input)
  }
`;
export const editMyBankDetails = /* GraphQL */ `
  mutation EditMyBankDetails($input: EditMyBankDetailsInput) {
    EditMyBankDetails(input: $input)
  }
`;
export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity($input: CreateCommunityInput) {
    CreateCommunity(input: $input)
  }
`;
export const createOneToOneCommunity = /* GraphQL */ `
  mutation CreateOneToOneCommunity($input: CreateOneToOneCommunityInput) {
    CreateOneToOneCommunity(input: $input)
  }
`;
export const joinCommunity = /* GraphQL */ `
  mutation JoinCommunity($input: JoinCommunityInput) {
    JoinCommunity(input: $input) {
      user_created_on
      user_first_name
      user_last_name
      user_profile_pic_url
      linkedin_profile_url
      executive_email_id
      preference_bankxchange_chat
      preference_office_contact_number
      bank_name
      user_email_id
      user_contact_number
      preference_business_email_id
      address_line_1
      address_line_2
      city
      state
      zipcode
      is_impersonated_user
      role_id
      preference_user_contact_number
      user_id
      office_contact_number
      bank_id
      executive_first_name
      executive_last_name
      user_status
      bank_details {
        bank_state
        internal_bank_name
        contact_person_email_id
        bank_address
        bank_country
        contact_person_role_id
        bank_created_on
        bank_zipcode
        bank_status
        bank_id
        contact_person_user_name
        bank_name
        no_of_users
        contact_person_contact_number
        bank_city
        bank_primary_regulator
        bank_no_of_branches
        bank_website
        bank_state_footprints
        bank_hq_state
        bank_total_assets
        bank_stock_ticker
      }
      role_details {
        role_id
        internal_role_name
        no_of_insights
        no_of_users
        role_created_on
        role_description
        role_name
        role_status
      }
      s3_details {
        access_key
        bucket_name
        secret_access_key
        region
      }
      community_details {
        no_of_users
      }
      version_details {
        version_title
        version_publised_on
        force_upgrade_version
        version_number
      }
    }
  }
`;
export const leaveCommunity = /* GraphQL */ `
  mutation LeaveCommunity($input: LeaveCommunityInput) {
    LeaveCommunity(input: $input) {
      user_created_on
      user_first_name
      user_last_name
      user_profile_pic_url
      linkedin_profile_url
      executive_email_id
      preference_bankxchange_chat
      preference_office_contact_number
      bank_name
      user_email_id
      user_contact_number
      preference_business_email_id
      address_line_1
      address_line_2
      city
      state
      zipcode
      is_impersonated_user
      role_id
      preference_user_contact_number
      user_id
      office_contact_number
      bank_id
      executive_first_name
      executive_last_name
      user_status
      bank_details {
        bank_state
        internal_bank_name
        contact_person_email_id
        bank_address
        bank_country
        contact_person_role_id
        bank_created_on
        bank_zipcode
        bank_status
        bank_id
        contact_person_user_name
        bank_name
        no_of_users
        contact_person_contact_number
        bank_city
        bank_primary_regulator
        bank_no_of_branches
        bank_website
        bank_state_footprints
        bank_hq_state
        bank_total_assets
        bank_stock_ticker
      }
      role_details {
        role_id
        internal_role_name
        no_of_insights
        no_of_users
        role_created_on
        role_description
        role_name
        role_status
      }
      s3_details {
        access_key
        bucket_name
        secret_access_key
        region
      }
      community_details {
        no_of_users
      }
      version_details {
        version_title
        version_publised_on
        force_upgrade_version
        version_number
      }
    }
  }
`;
export const removeUserFromCommunity = /* GraphQL */ `
  mutation RemoveUserFromCommunity($input: RemoveUserFromCommunityInput) {
    RemoveUserFromCommunity(input: $input) {
      user_created_on
      user_first_name
      user_last_name
      user_profile_pic_url
      linkedin_profile_url
      executive_email_id
      preference_bankxchange_chat
      preference_office_contact_number
      bank_name
      user_email_id
      user_contact_number
      preference_business_email_id
      address_line_1
      address_line_2
      city
      state
      zipcode
      is_impersonated_user
      role_id
      preference_user_contact_number
      user_id
      office_contact_number
      bank_id
      executive_first_name
      executive_last_name
      user_status
      bank_details {
        bank_state
        internal_bank_name
        contact_person_email_id
        bank_address
        bank_country
        contact_person_role_id
        bank_created_on
        bank_zipcode
        bank_status
        bank_id
        contact_person_user_name
        bank_name
        no_of_users
        contact_person_contact_number
        bank_city
        bank_primary_regulator
        bank_no_of_branches
        bank_website
        bank_state_footprints
        bank_hq_state
        bank_total_assets
        bank_stock_ticker
      }
      role_details {
        role_id
        internal_role_name
        no_of_insights
        no_of_users
        role_created_on
        role_description
        role_name
        role_status
      }
      s3_details {
        access_key
        bucket_name
        secret_access_key
        region
      }
      community_details {
        no_of_users
      }
      version_details {
        version_title
        version_publised_on
        force_upgrade_version
        version_number
      }
    }
  }
`;
export const takeActionOnRequest = /* GraphQL */ `
  mutation TakeActionOnRequest($input: TakeActionOnRequestInput) {
    TakeActionOnRequest(input: $input)
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage(
    $community_id: String!
    $message_text: String!
    $message_type: MessageTypeEnum!
    $message_sent_type: MessagSentTypeEnum!
    $has_reply_message: Boolean!
    $no_of_replies: Int!
    $replied_to_message_id: String!
    $replied_for_message_type: MessageTypeEnum!
    $replied_for_message_text: String!
  ) {
    SendMessage(
      community_id: $community_id
      message_text: $message_text
      message_type: $message_type
      message_sent_type: $message_sent_type
      has_reply_message: $has_reply_message
      no_of_replies: $no_of_replies
      replied_to_message_id: $replied_to_message_id
      replied_for_message_type: $replied_for_message_type
      replied_for_message_text: $replied_for_message_text
    ) {
      community_id
      message_text
      messaged_by_id
      messaged_by_user_details {
        user_created_on
        user_first_name
        user_last_name
        user_profile_pic_url
        linkedin_profile_url
        executive_email_id
        preference_bankxchange_chat
        preference_office_contact_number
        bank_name
        user_email_id
        user_contact_number
        preference_business_email_id
        address_line_1
        address_line_2
        city
        state
        zipcode
        is_impersonated_user
        role_id
        preference_user_contact_number
        user_id
        office_contact_number
        bank_id
        executive_first_name
        executive_last_name
        user_status
        bank_details {
          bank_state
          internal_bank_name
          contact_person_email_id
          bank_address
          bank_country
          contact_person_role_id
          bank_created_on
          bank_zipcode
          bank_status
          bank_id
          contact_person_user_name
          bank_name
          no_of_users
          contact_person_contact_number
          bank_city
          bank_primary_regulator
          bank_no_of_branches
          bank_website
          bank_state_footprints
          bank_hq_state
          bank_total_assets
          bank_stock_ticker
        }
        role_details {
          role_id
          internal_role_name
          no_of_insights
          no_of_users
          role_created_on
          role_description
          role_name
          role_status
        }
        s3_details {
          access_key
          bucket_name
          secret_access_key
          region
        }
        community_details {
          no_of_users
        }
        version_details {
          version_title
          version_publised_on
          force_upgrade_version
          version_number
        }
      }
      message_id
      messaged_on
      message_type
      message_sent_type
      has_reply_message
      no_of_replies
      replied_to_message_id
      replied_for_message_type
      replied_for_message_text
      reactions {
        community_id
        reaction_id
        message_id
        reacted_time
        reacted_by_user_id
        reacted_by_user_first_name
        reacted_by_user_last_name
        reaction_type
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput) {
    UpdateMessage(input: $input)
  }
`;
export const addReaction = /* GraphQL */ `
  mutation AddReaction(
    $community_id: String!
    $message_id: String!
    $reacted_by_user_id: String!
    $reacted_by_user_first_name: String!
    $reacted_by_user_last_name: String
    $reaction_type: String!
  ) {
    AddReaction(
      community_id: $community_id
      message_id: $message_id
      reacted_by_user_id: $reacted_by_user_id
      reacted_by_user_first_name: $reacted_by_user_first_name
      reacted_by_user_last_name: $reacted_by_user_last_name
      reaction_type: $reaction_type
    ) {
      community_id
      reaction_id
      message_id
      reacted_time
      reacted_by_user_id
      reacted_by_user_first_name
      reacted_by_user_last_name
      reaction_type
    }
  }
`;
export const removeReaction = /* GraphQL */ `
  mutation RemoveReaction(
    $community_id: String!
    $message_id: String!
    $reaction_id: String!
  ) {
    RemoveReaction(
      community_id: $community_id
      message_id: $message_id
      reaction_id: $reaction_id
    ) {
      community_id
      reaction_id
      message_id
      reacted_time
      reacted_by_user_id
      reacted_by_user_first_name
      reacted_by_user_last_name
      reaction_type
    }
  }
`;
export const updateMessageReaction = /* GraphQL */ `
  mutation UpdateMessageReaction($input: UpdateMessageReactionsInput) {
    UpdateMessageReaction(input: $input)
  }
`;
export const respondToEvent = /* GraphQL */ `
  mutation RespondToEvent($input: RespondToEventInput) {
    RespondToEvent(input: $input)
  }
`;
export const generatingS3UploadUrl = /* GraphQL */ `
  mutation GeneratingS3UploadUrl($input: GeneratingS3UploadUrlInput) {
    GeneratingS3UploadUrl(input: $input)
  }
`;
export const updateRoleUsersActivitiesDownloadInsights = /* GraphQL */ `
  mutation UpdateRoleUsersActivitiesDownloadInsights(
    $input: UpdateRoleUsersActivitiesDownloadInsightsInput
  ) {
    UpdateRoleUsersActivitiesDownloadInsights(input: $input)
  }
`;
export const createUpdateCommunitiesLastMessage = /* GraphQL */ `
  mutation CreateUpdateCommunitiesLastMessage(
    $input: CreateUpdateCommunitiesLastMessageInput
  ) {
    CreateUpdateCommunitiesLastMessage(input: $input)
  }
`;
export const postSignIn = /* GraphQL */ `
  mutation PostSignIn {
    PostSignIn
  }
`;
