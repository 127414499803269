import { API, graphqlOperation } from "aws-amplify";
import { postSignIn } from "@/graphql/mutations.js";

export const LoginRestApi = {
  data: () => ({}),
  methods: {
    async LoginRestApiMethod() {
      try {
        let result = await API.graphql(
          graphqlOperation(postSignIn, {
            input: {},
          })
        );
        let ResultObj = JSON.parse(result.data.PostSignIn);
        return ResultObj;
      } catch (error) {
        console.log("Check The Error", error);
        return "ERROR";
      }
    },
  },
};
