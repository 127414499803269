import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import SIGNUP from "../views/MainHomePages/SignUpPage.vue";
import AcceptInvitation from "../views/MainHomePages/AcceptInvitation.vue";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/INVITEUSER/:InvitedMemberDetails",
    name: "SIGNUP",
    component: SIGNUP,
  },
  {
    path: "/ACCEPTINVITATION/:InvitedEADetails",
    name: "AcceptInvitation",
    component: AcceptInvitation,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/HomePage",
        name: "Welcome back!",
        component: () => import(/* webpackChunkName: "HomePage" */ "../views/ChildHomePages/HomePage.vue"),
      },
      {
        path: "/Resources",
        name: "Resources",
        component: () => import(/* webpackChunkName: "Resources" */ "../views/ChildHomePages/Resources.vue"),
      },
      {
        path: "/Insights",
        name: "Insights",
        component: () => import(/* webpackChunkName: "Insights" */ "../views/ChildHomePages/Insights.vue"),
      },
     
      {
        path: "/MyProfile",
        name: "My Profile",
        component: () => import(/* webpackChunkName: "MyProfile" */ "../views/ChildHomePages/MyProfile.vue"),
      },
      {
        path: "/CommunityMembers",
        name: "All Community Members",
        component: () => import(/* webpackChunkName: "CommunityMembers" */ "../views/ChildHomePages/CommunityMembers.vue"),
      },
      {
        path: "/CommunityDiscussions",
        name: "CommunityDiscussions",
        component: () => import(/* webpackChunkName: "CommunityDiscussions" */ "../views/ChildHomePages/CommunityDiscussions.vue"),
      },
      {
        path: "/AllDiscussion",
        name: "All Discussions",
        component: () => import(/* webpackChunkName: "AllDiscussion" */ "../views/ChildHomePages/AllDiscussion.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

