import { Auth } from "aws-amplify";
export const GetCodeValidation = {
  data: () => ({}),
  methods: {
    async GetCodeValidationMethod(user_session, otp) {
      try {
        console.log("User Session", user_session);
        let verifyResult = await Auth.sendCustomChallengeAnswer(user_session, otp);
        if (verifyResult) {
          return {
            status: "SUCCESS",
            status_message: "OTP verified successful",
          };
        }
      } catch (error) {
        return {
          status: "ERROR",
          status_message: error,
        };
      }
    },
  },
};
