import { openDB } from "idb";

export const initiateLocalCacheDB = {
  data: () => ({}),
  methods: {
    // Initialize the database or check and create a store
    async checkAndCreateAllStores() {
      try {
        const storeConfigs = {
          LATEST_ACTIVITY: { keyPath: "latest_activity_id" },
          MY_PROFILE: { keyPath: "user_id" },
          RESOURCES: { keyPath: "resource_id" },
          INSIGHTS_CATEGORIES: { keyPath: "category_id" },
          INSIGHTS: { keyPath: "insight_id" },
          GLOBAL_CALENDAR: { keyPath: "event_id" },
          DOCUMENT_VAULT: { keyPath: "document_id" },
          COMMUNITY_CORNER: { keyPath: "community_corner_id" },
          SURVEYS: { keyPath: "survey_id" },
          COMMUNITY_MEMBERS: { keyPath: "user_id" },
          COMMUNITY_DISCUSSIONS: { keyPath: "community_id" },
          DIRECT_DISCUSSIONS: { keyPath: "community_id" },
          STORED_MESSAGES: { keyPath: "community_id" },
        };

        // Increment database version to trigger upgrade
        const db = await openDB("BXC_LOCALDATABASE", 7, {
          upgrade(db) {
            console.log("Upgrade triggered");
            for (const [storeName, config] of Object.entries(storeConfigs)) {
              if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName, config);
                console.log(`Store created: ${storeName}`);
              } else {
                console.log(`Store already exists: ${storeName}`);
              }
            }
          },
        });
        console.log("Database initialized:", db);
        console.log("All object stores:", Array.from(db.objectStoreNames));

        // console.log("Database setup complete.");
        // return db;
      } catch (error) {
        console.error("Error initializing DB:", error);
        throw error;
      }
    },
    async getAllItems(storeName) {
      try {
        const db = await openDB("BXC_LOCALDATABASE", 7);
        const store = db.transaction(storeName, "readonly").objectStore(storeName);
        const items = await store.getAll();
        return items;
      } catch (error) {
        console.error("Error getting all items:", error);
        throw error;
      }
    },

    async listStore(storeName) {
      try {
        const db = await openDB("BXC_LOCALDATABASE", 7);
        if (db.objectStoreNames.contains(storeName)) {
          console.log(`Store exists: ${storeName}`);
          return storeName;
        } else {
          console.log(`Store does not exist: ${storeName}`);
          return null;
        }
      } catch (error) {
        console.error("Error checking store:", error);
        throw error;
      }
    },
    async resetDatabase(StoreName) {
      try {
        await this.clearAllStores();
        console.log("Old Stores Cleared.");

        // const db = await this.checkAndCreateStore(StoreName);
        // console.log("New database created with stores.");

        // return db;
      } catch (error) {
        console.error("Error resetting the database:", error);
        throw error;
      }
    },

    async deleteDatabase() {
      try {
        const deleteRequest = indexedDB.deleteDatabase("BXC_LOCALDATABASE");

        return new Promise((resolve, reject) => {
          deleteRequest.onsuccess = () => {
            console.log("Database deleted successfully.");
            resolve(); // Resolve the promise
          };

          deleteRequest.onerror = (event) => {
            console.error("Error deleting the database:", event.target.error);
            reject(new Error("Failed to delete database")); // Reject the promise
          };

          deleteRequest.onblocked = () => {
            console.warn("The deletion of the database is blocked. Please close other tabs or instances using the database.");
            reject(new Error("Database deletion blocked"));
          };
        });
      } catch (error) {
        console.error("Unexpected error deleting the database:", error);
        throw error; // Rethrow the error for higher-level handling
      }
    },
    async clearAllStores() {
      try {
        const db = await openDB("BXC_LOCALDATABASE", 7);
        const transaction = db.transaction(db.objectStoreNames, "readwrite");

        for (const storeName of db.objectStoreNames) {
          const store = transaction.objectStore(storeName);
          await store.clear();
          console.log(`Cleared data from store: ${storeName}`);
        }

        await transaction.done;
        console.log("All stores cleared successfully.");
      } catch (error) {
        console.error("Error clearing stores:", error);
      }
    },
    async listAllStoresInDatabase() {
      try {
        const db = await openDB("BXC_LOCALDATABASE", 7);
        console.log("check db", db);
        const allStores = Array.from(db.objectStoreNames);
        console.log("All stores in the database:", allStores);
        return allStores;
      } catch (error) {
        console.error("Error listing all stores in the database:", error);
        throw error;
      }
    },
  },
};
