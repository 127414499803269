<template>
  <v-app :style="{ background: customBackgroundColor }">
    <!-- <v-main> -->
    <TermsAndCondtions :dialogForTermsAndConditions="dialogForTermsAndConditions" @clicked="dialogForTermsAndConditions = false" />

    <v-row no-gutters align="center" justify="center" :Style="{ height: `${VuetifyObj.height}px` }">
      <v-col cols="12">
        <Snackbar :SnackBarComponent="SnackBarComponent" />
      </v-col>
      <v-col cols="12" sm="12" md="12" xs="12" align="center" justify="center">
        <v-card-text>
          <v-card flat max-width="500px" v-if="current_view == 'CARD'" class="signUpPageCard">
            <v-window v-model="Stepper">
              <v-window-item :value="1">
                <v-card class="elevation-0 text-left">
                  <div class="fontStyleVariant4">Step 1 / 5</div>
                  <div class="fontStyleVariant1 mt-2">Let's create your</div>
                  <div class="fontStyleVariant1">bankXchange account</div>
                  <div class="fontStyleVariant2 mt-2">Please create an account login in order to get started.</div>
                  <div class="gapVariant1"></div>
                  <v-form ref="form1">
                    <div class="fontStyleVariant2 mb-2">Business Email Address</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      disabled
                      readonly
                      v-model="user_details.user_email_id"
                      :rules="[(v) => !!v || 'Required']"
                      height="48px"
                      class="fontFamilyInter"
                    ></v-text-field>
                    <div class="fontStyleVariant2 mb-2" v-if="is_otp_enabled">Verification code</div>
                    <v-otp-input
                      v-if="is_otp_enabled"
                      :length="6"
                      class="OTPField fontFamilyInter"
                      type="number"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => (v && v.length >= 6) || (v && v.length <= 6) || 'Password Must Have Min. 6 characters',
                        (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                      ]"
                      v-model="user_details.verification_code"
                    ></v-otp-input>
                  </v-form>
                  <!-- <v-card-actions class="pa-0">
                      <v-checkbox density="compact" class="pa-0 ma-0" v-model="agree_terms_and_condtions"> </v-checkbox>
                      <div class="loginCardTextVariant4 mt-n5">
                        By checking this box, you agree to the
                        <span @click.stop="dialogForTermsAndConditions = true" class="text-blueColorVariant1 FontUnderline cursorPointer">Terms & Conditions </span>
                      </div>
                    </v-card-actions> -->
                  <!--:disabled="!agree_terms_and_condtions"-->
                  <v-card-actions v-if="is_otp_enabled">
                    <div>
                      Didn't receive the OTP? Click here to
                      <span><v-btn text color="primary" small class="text-capitalize TextUnderline ml-n2" @click="validateStepperOneMethod('VERIFY_EMAIL')">Resend OTP</v-btn></span>
                    </div>
                  </v-card-actions>
                  <v-card-actions class="px-0 mx-0">
                    <v-btn dark variant="flat" :loading="loadingStepper1" @click="validateStepperOneMethod(is_otp_enabled ? 'VERIFY_OTP' : 'VERIFY_EMAIL')" class="text-capitalize buttonClassVariant1">
                      {{ is_otp_enabled ? "Verify OTP" : "Verify Email" }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-window-item>
              <v-window-item :value="2">
                <v-card class="elevation-0" align="left">
                  <div class="fontStyleVariant4">Step 2 / 5</div>
                  <div class="fontStyleVariant1">Please Confirm your password</div>
                  <div class="fontStyleVariant2 mt-2">We require two-factor authentication for enhanced security</div>
                  <div class="gapVariant2"></div>
                  <v-form ref="form2">
                    <div class="fontStyleVariant2 mb-2">Create Password</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      height="48px"
                      class="fontFamilyInter SignUpAutoFill"
                      :type="isPwdLoginCreate ? 'text' : 'password'"
                      v-model="user_details.user_create_password"
                      @click:append-inner="isPwdLoginCreate = !isPwdLoginCreate"
                      :append-inner-icon="isPwdLoginCreate ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => !!v || 'Password is required',
                        (v) => (v && v.length >= 8) || 'Password must be at least 8 characters long',
                        (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
                        (v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
                        (v) => /[^a-zA-Z0-9]/.test(v) || 'Password must contain at least one special character',
                        (v) => /\d/.test(v) || 'Password must contain at least one number',
                      ]"
                      placeholder="8+ Char. incl. upper & lower, numeric & special"
                    ></v-text-field>
                    <div class="fontStyleVariant2 mb-2">Confirm Password</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      :type="isPwdLoginConfirm ? 'text' : 'password'"
                      v-model="user_details.user_confirm_password"
                      @click:append-inner="isPwdLoginConfirm = !isPwdLoginConfirm"
                      :append-inner-icon="isPwdLoginConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      :error="!isPasswordValid"
                      height="48px"
                      class="fontFamilyInter"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => !!v || 'Password is required',
                        (v) => (v && v.length >= 8) || 'Password must be at least 8 characters long',
                        (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
                        (v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
                        (v) => /[^a-zA-Z0-9]/.test(v) || 'Password must contain at least one special character',
                        (v) => /\d/.test(v) || 'Password must contain at least one number',
                      ]"
                      placeholder="8+ Char. incl. upper & lower, numeric & special"
                    ></v-text-field>
                    <!-- <v-text-field 
                              density="compact"
                              variant="outlined"
                              height="48px"
                              class="fontFamilyInter"
                              v-model="user_details.verification_code"
                              :rules="[
                                (v) => !!v || 'Required',
                                (v) =>
                                  (v && v.length >= 6) ||
                                  (v && v.length <= 6) ||
                                  'Password Must Have Min. 6 characters',
                                (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                              ]"
                              placeholder="6-digit code"
                            ></v-text-field> -->
                  </v-form>

                  <v-btn dark variant="flat" :loading="loadingStepper2" @click="validateStepperTwoMethod()" class="text-capitalize buttonClassVariant1"> Submit </v-btn>
                </v-card>
              </v-window-item>
              <v-window-item :value="3">
                <v-card-text class="pa-0 text-left">
                  <v-card v-if="loadingActivatingAccount">
                    <div class="fontStyleVariant4">Step 2 / 3</div>
                    <div class="fontStyleVariant1">One moment please</div>
                    <div class="gapVariant3"></div>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="12">
                        <v-card flat class="loadingInsideCard" align="center">
                          <v-progress-circular indeterminate color="primary" size="100" width="10" class="mt-6"></v-progress-circular>
                          <div class="fontStyleVariant3 mb-2">Activating your account.</div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="4">
                <v-card class="elevation-0 text-left">
                  <div class="fontStyleVariant4">Step 3 / 5</div>
                  <div class="fontStyleVariant1 mt-2">Complete your profile</div>
                  <div class="fontStyleVariant2 mt-2">By completing your profile, we can add you to the resource group community</div>

                  <v-form class="mt-4" ref="form4">
                    <div class="fontStyleVariant2 mb-2">Bank Name <span class="text-red">*</span></div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      height="48px"
                      class="fontFamilyInter"
                      readonly
                      disabled
                      filled
                      v-model="contact_details.bank_details.bank_name"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>

                    <div class="fontStyleVariant2 mb-2">Resource Group <span class="text-red">*</span></div>
                    <v-text-field
                      density="compact"
                      filled
                      disabled
                      variant="outlined"
                      readonly
                      height="48px"
                      class="fontFamilyInter"
                      v-model="contact_details.role_name"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="6">
                        <div class="fontStyleVariant2 mb-2">
                          First Name
                          <span class="text-red">*</span>
                        </div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          height="48px"
                          class="fontFamilyInter"
                          v-model="contact_details.user_first_name"
                          :rules="[(v) => !!v || 'Required', (v) => /^(?=[a-zA-Z]{0,30}$)[A-Za-z]{0,64}$/.test(v) || 'Characters only']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" class="pl-2">
                        <div class="fontStyleVariant2 mb-2">
                          Last Name
                          <span class="text-red">*</span>
                        </div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          height="48px"
                          class="fontFamilyInter"
                          v-model="contact_details.user_last_name"
                          :rules="[(v) => !!v || 'Required', (v) => /^(?=[a-zA-Z]{0,30}$)[A-Za-z]{0,64}$/.test(v) || 'Characters only']"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <div class="fontStyleVariant2 mt-n2 mb-2">
                      Mobile Number
                      <span class="text-red">*</span>
                    </div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      height="48px"
                      class="fontFamilyInter"
                      v-model="contact_details.user_contact_number"
                      @input="FormattingMobilePhoneNumber"
                      :rules="[(v) => !!v || 'Phone number is required', (v) => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Invalid phone number format (e.g., (xxx) xxx-xxxx)']"
                    ></v-text-field>
                    <div class="fontStyleVariant2 mb-2">Office Number</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      height="48px"
                      class="fontFamilyInter"
                      v-model="contact_details.office_contact_number"
                      :rules="[
                        (v) => !v || /^[^a-zA-Z]+$/.test(v) || 'Only numbers are allowed',
                        (v) => !v || /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Invalid phone number format (e.g., (xxx) xxx-xxxx)',
                      ]"
                      @input="FormattingOfficePhoneNumber"
                    ></v-text-field>
                    <v-row class="py-0 mt-n4">
                      <v-col cols="12" xs="12" md="12" sm="12">
                        <div class="fontStyleVariant2 mb-2">
                          Address Line 1
                          <span class="text-red">*</span>
                        </div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          :rules="[(v) => !!v || 'Required']"
                          v-model="contact_details.address_line_1"
                          height="48px"
                          class="fontFamilyInter"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" md="12" sm="12" class="mt-n5">
                        <div class="fontStyleVariant2 mb-2">Address Line 2</div>
                        <v-text-field density="compact" variant="outlined" v-model="contact_details.address_line_2" height="48px" class="fontFamilyInter"></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" md="6" sm="12" class="mt-n5">
                        <div class="fontStyleVariant2 mb-2">City <span class="text-red">*</span></div>
                        <v-text-field density="compact" variant="outlined" :rules="[(v) => !!v || 'Required']" v-model="contact_details.city" height="48px" class="fontFamilyInter"></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" md="6" sm="12" class="mt-n5">
                        <div class="fontStyleVariant2 mb-2">Zip <span class="text-red">*</span></div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          :rules="[(v) => !v || /^[0-9]{5}$/.test(v) || 'ZIP Code must be 5 digits']"
                          v-model="contact_details.zipcode"
                          height="48px"
                          class="fontFamilyInter"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="fontStyleVariant2 mb-2">State <span class="text-red">*</span></div>
                    <v-select
                      density="compact"
                      variant="outlined"
                      v-model="contact_details.state"
                      height="48px"
                      item-title="abbreviation"
                      item-value="abbreviation"
                      :items="stateList"
                      class="fontFamilyInter"
                      :rules="[(v) => !!v || 'Required']"
                    >
                    </v-select>
                    <div class="fontStyleVariant2 mb-2">Linkedin Profile URL (Optional)</div>
                    <v-text-field density="compact" variant="outlined" v-model="contact_details.linkedin_profile_url" height="48px" class="fontFamilyInter"></v-text-field>

                    <v-card-actions class="pa-0">
                      <div :class="!EnableExecutiveAssistant ? 'fontStyleVariant3' : 'fontStyleVariant2'" class="cursorPointer" @click="EnableExecutiveAssistant = true">
                        Add Executive Assistant contact info
                        <v-icon v-if="!EnableExecutiveAssistant" color="#0354e0">mdi-plus</v-icon>
                      </div>
                      <v-spacer />
                      <div v-if="EnableExecutiveAssistant" class="fontStyleVariant3 cursorPointer" @click="EnableExecutiveAssistant = false">Cancel</div>
                    </v-card-actions>

                    <div class="fontStyleVariant5">Provide EA with access to my resource group community</div>
                    <v-card flat class="mt-2 pt-5" v-if="EnableExecutiveAssistant">
                      <v-row>
                        <v-col cols="12" xs="12" md="6" sm="12">
                          <div class="fontStyleVariant2 mb-2">
                            EA First Name
                            <span class="text-red">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            v-model="contact_details.executive_first_name"
                            height="48px"
                            :rules="EnableExecutiveAssistant ? [(v) => !!v || 'Required'] : []"
                            class="fontFamilyInter"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <div class="fontStyleVariant2 mb-2">
                            EA Last Name
                            <span class="text-red">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            :rules="EnableExecutiveAssistant ? [(v) => !!v || 'Required'] : []"
                            v-model="contact_details.executive_last_name"
                            height="48px"
                            class="fontFamilyInter"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <div class="fontStyleVariant2 mb-2">
                        EA Email Address
                        <span class="text-red">*</span>
                      </div>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        v-model="contact_details.executive_email_address"
                        @keyup="onEnteringExecutingEmailIDExistsMethod()"
                        height="48px"
                        :rules="EnableExecutiveAssistant ? [(v) => !!v || 'Required', (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid Email'] : []"
                        class="fontFamilyInter"
                      ></v-text-field>
                    </v-card>
                  </v-form>
                </v-card>
                <v-card-actions class="pt-8">
                  <v-btn dark class="text-capitalize buttonClassVariant1" variant="flat" @click="validateStepperFourMethod()"> Submit </v-btn>

                  <v-spacer />
                </v-card-actions>
                <div class="fontStyleVariant5 text-left">
                  By continuing, you agree to the
                  <span class="text-blueColorVariant1 FontUnderline">Terms & Conditions </span>
                </div>
              </v-window-item>
              <v-window-item :value="5">
                <v-card class="elevation-0 text-left">
                  <div class="fontStyleVariant4">Step 4 / 5</div>
                  <div class="fontStyleVariant1 mt-2">Confirm your communication preferences</div>
                  <div class="fontStyleVariant2 mt-2">Your communication preferences will help your resource group know how best to reach you</div>
                  <div class="fontStyleVariant6 my-4">Contact Settings</div>
                  <v-form ref="form5">
                    <v-row>
                      <v-col cols="12" md="8" xs="12" sm="12">
                        <div class="fontStyleVariant7">Business Email Address</div>
                        <div class="fontStyleVariant5">
                          Allow resource group members to <br />
                          contact {{ user_details.user_email_id }}
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12" align="center">
                        <v-chip-group v-model="ContactSettingsValue.IsBusinessEmailAddress" column mandatory variant="flat">
                          <v-chip
                            :class="ContactSettingsValue.IsBusinessEmailAddress === val.value ? 'profilecustomizedChip2' : 'profilecustomizedChip3'"
                            variant="flat"
                            v-for="(val, idx) in ContactSettings"
                            :key="idx"
                            :value="val.value"
                            :text="val.text"
                            label
                          >
                          </v-chip>
                        </v-chip-group>
                      </v-col>

                      <v-col cols="12" md="8" xs="12" sm="12">
                        <div class="fontStyleVariant7">Mobile Number</div>
                        <div class="fontStyleVariant5">
                          Allow resource group members to <br />
                          contact
                          {{ contact_details.user_contact_number }}
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12" align="center">
                        <v-chip-group mandatory v-model="ContactSettingsValue.IsBusinessMobilePhone" column>
                          <v-chip
                            :class="ContactSettingsValue.IsBusinessMobilePhone === val.value ? 'profilecustomizedChip2' : 'profilecustomizedChip3'"
                            v-for="(val, idx) in ContactSettings"
                            :key="idx"
                            :value="val.value"
                            :text="val.text"
                            label
                          >
                          </v-chip>
                        </v-chip-group>
                      </v-col>

                      <v-col cols="12" md="8" xs="12" sm="12">
                        <div class="fontStyleVariant7">Office Number</div>
                        <div class="fontStyleVariant5">
                          Allow resource group members to <br />
                          contact
                          {{ contact_details.office_contact_number }}
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12" align="center">
                        <v-chip-group mandatory v-model="ContactSettingsValue.IsBusinessOfficePhone" column>
                          <v-chip
                            :class="ContactSettingsValue.IsBusinessOfficePhone === val.value ? 'profilecustomizedChip2' : 'profilecustomizedChip3'"
                            v-for="(val, idx) in ContactSettings"
                            :key="idx"
                            :value="val.value"
                            :text="val.text"
                            label
                          >
                          </v-chip>
                        </v-chip-group>
                      </v-col>

                      <v-col cols="12" md="8" xs="12" sm="12">
                        <div class="fontStyleVariant7">bankXchange Chat</div>
                        <div class="fontStyleVariant5">
                          Allow resource group members to <br />
                          message
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" xs="12" sm="12" align="center">
                        <v-chip-group mandatory v-model="ContactSettingsValue.IsBankXchangeChatEnable" tile column>
                          <v-chip
                            :class="ContactSettingsValue.IsBankXchangeChatEnable === val.value ? 'profilecustomizedChip2' : 'profilecustomizedChip3'"
                            v-for="(val, idx) in ContactSettings"
                            :key="idx"
                            :value="val.value"
                            :text="val.text"
                            label
                          >
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn variant="flat" :loading="loadingStepper6" @click="validateStepperFiveMethod()" dark class="text-capitalize buttonClassVariant1 mt-4"> Save & Finish </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-window-item>
              <v-window-item :value="6">
                <v-card class="elevation-0 text-left">
                  <div class="fontStyleVariant4 mb-2">Finished</div>
                  <div>
                    <span class="fontStyleVariant8">Congrats!</span>
                    <span class="fontStyleVariant1"> You are now a bankXchange member </span>
                  </div>

                  <div class="fontStyleVariant2 mt-6">Thank you for submitting your contact information and communication preferences. You are now able to access bankXchange Portal.</div>
                  <v-card-actions>
                    <div dark variant="flat" :loading="loadingStepper6" @click="validateStepperSixMethod()" class="buttonClassVariant1 text-white pa-6 ml-n2 mt-5">Continue To bankXchange</div>
                  </v-card-actions>
                  <div class="fontStyleVariant2 mt-8">You will also receive an email inviting you to test bankXchange mobile application using TestFlight.</div>

                  <div class="fontStyleVariant5 mt-8">
                    Should you have any questions, please reach out to the <b>MBCA bankXchange</b> team at <b><u>help@bankxchange.com</u></b
                    >.
                  </div>
                </v-card>
              </v-window-item>
              <v-window-item :value="7">
                <v-card-text class="pa-0 text-left">
                  <v-card>
                    <div class="fontStyleVariant1">One moment please</div>
                    <div class="gapVariant3"></div>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="12">
                        <v-card flat class="loadingInsideCard" align="center">
                          <v-progress-circular indeterminate color="primary" size="100" width="10" class="mt-6"></v-progress-circular>
                          <div class="fontStyleVariant3 mb-2">Fetching account details</div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="8">
                <v-card-text class="pa-0 text-left">
                  <v-card>
                    <div class="fontStyleVariant1 text-center">Something went wrong..!</div>
                    <div class="gapVariant3"></div>
                    <!-- <v-row no-gutters>
                              <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card flat class="loadingInsideCard" align="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="100"
                                    width="10"
                                    class="mt-6"
                                  ></v-progress-circular>
                                  <div class="fontStyleVariant3 mb-2">Fetching account details</div>
                                </v-card>
                              </v-col>
                            </v-row> -->
                  </v-card>
                </v-card-text>
              </v-window-item>
            </v-window>
          </v-card>
        </v-card-text>
      </v-col>
    </v-row>
    <!-- </v-main> -->
  </v-app>
</template>
<script>
import stateList from "@/JsonFiles/stateList.json";
import { Auth } from "aws-amplify";
import { GetAPIKey } from "@/mixins/RestAPIs/GetAPIKey.js";
import { GetVericationCodeForUserSignIn } from "@/mixins/RestAPIs/GetVericationCodeForUserSignIn.js";
import { GetCodeValidation } from "@/mixins/RestAPIs/GetCodeValidation";
import { GetActivateUser } from "@/mixins/RestAPIs/GetActivateUser";
import { GetUserStatus } from "@/mixins/RestAPIs/GetUserStatus.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { useDisplay } from "vuetify";
import TermsAndCondtions from "@/components/Extras/TermsAndCondtions.vue";
export default {
  mixins: [GetAPIKey, GetActivateUser, GetCodeValidation, GetVericationCodeForUserSignIn, GetUserStatus],
  components: {
    Snackbar,
    TermsAndCondtions,
  },
  data: () => ({
    Stepper: 1,
    contact_count: 0,

    userAgent: "",
    customBackgroundColor: "#f0f8ff",
    current_view: "CARD",

    renderComp: true,
    password: "",
    passwordValidationIcon: "",
    passwordValidationMessage: "",

    is_otp_enabled: false,
    isPasswordValid: true,
    loadingStepper1: false,
    loadingStepper2: false,
    loadingStepper6: false,
    isPwdLoginConfirm: false,
    isPwdLoginCreate: false,
    loadingActivatingAccount: false,
    EnableExecutiveAssistant: false,
    agree_terms_and_condtions: false,
    dialogForTermsAndConditions: false,

    VuetifyObj: {},
    ContactSettingsValue: {
      IsBusinessEmailAddress: true,
      IsBusinessMobilePhone: true,
      IsBusinessOfficePhone: true,
      IsBankXchangeChatEnable: true,
    },
    user_details: {
      user_email_id: "",
      user_create_password: "",
      user_confirm_password: "",
      verification_code: "",
      role_name: "",
      executive_full_name: "",
      executive_email_address: "",
    },
    contact_details: {
      user_first_name: "",
      bank_details: {},
      role_name: "",
      role_id: "",
      executive_first_name: "",
      executive_last_name: "",
      user_contact_number: "",
      office_contact_number: "",
      linkedin_profile_url: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      zipcode: "",
    },
    SnackBarComponent: {},

    stateList: [],
    Profile_details: [],
    ListBanks: ["ListBanks", "FewBanks"],
    ContactSettings: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  }),
  watch: {
    "user_details.user_email_id"(val) {
      if (val) {
        this.user_details.user_email_id = val.toLowerCase();
      }
    },
  },
  computed: {
    IsValidated() {
      return this.contact_count === this.Profile_details.length;
    },
  },
  async mounted() {
    this.Stepper = 5;
    this.stateList = stateList;
    this.VuetifyObj = useDisplay();
    setTimeout(() => {
      this.user_details.user_create_password = "";
      this.user_details.user_confirm_password = "";

      if (this.$refs.form1) {
        this.$refs.form1.resetValidation();
      }
    }, 300);
    await this.GetUserEmailMethod();
  },
  methods: {
    removeSpaces() {
      this.contact_details.user_first_name = this.contact_details.user_first_name.replace(/\s/g, "");
    },
    FormattingOfficePhoneNumber() {
      this.contact_details.office_contact_number = this.contact_details.office_contact_number.replace(/[^\d]/g, "");
      if (this.contact_details.office_contact_number.length > 3) {
        this.contact_details.office_contact_number = `(${this.contact_details.office_contact_number.slice(0, 3)})` + " " + this.contact_details.office_contact_number.slice(3);
      }
      if (this.contact_details.office_contact_number.length > 8) {
        this.contact_details.office_contact_number = this.contact_details.office_contact_number.slice(0, 9) + "-" + this.contact_details.office_contact_number.slice(9);
      }
    },
    FormattingMobilePhoneNumber() {
      this.contact_details.user_contact_number = this.contact_details.user_contact_number.replace(/[^\d]/g, "");
      if (this.contact_details.user_contact_number.length > 3) {
        this.contact_details.user_contact_number = `(${this.contact_details.user_contact_number.slice(0, 3)})` + " " + this.contact_details.user_contact_number.slice(3);
      }
      if (this.contact_details.user_contact_number.length > 8) {
        this.contact_details.user_contact_number = this.contact_details.user_contact_number.slice(0, 9) + "-" + this.contact_details.user_contact_number.slice(9);
      }
    },
    async onEnteringExecutingEmailIDExistsMethod() {
      if (this.contact_details.executive_email_address.length >= 9) {
        let userExistsResult = await this.GetUserStatusMethod(this.contact_details.executive_email_address);
        if (userExistsResult.Status != 1) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "This Email Address is already registered!!.",
          };
          this.contact_details.executive_email_address = "";
        }
      }
    },

    async GetUserEmailMethod() {
      let can_proceed = false;
      let userDetailswith3ands = atob(this.$route.params.InvitedMemberDetails).split("&&&");
      let userDetailswith1ands = atob(this.$route.params.InvitedMemberDetails).split("&");

      if (userDetailswith3ands.length == 5) {
        can_proceed = true;
        this.user_details.user_email_id = userDetailswith3ands[0].split("=")[1];
        this.user_details.role_id = userDetailswith3ands[1].split("=")[1];
        this.user_details.role_name = userDetailswith3ands[2].split("=")[1];
        this.contact_details.bank_details.bank_id = userDetailswith3ands[3].split("=")[1];
        this.contact_details.bank_details.bank_name = userDetailswith3ands[4].split("=")[1];
      }
      if (userDetailswith1ands.length == 5) {
        can_proceed = true;
        this.user_details.user_email_id = userDetailswith1ands[0].split("=")[1];
        this.user_details.role_id = userDetailswith1ands[1].split("=")[1];
        this.user_details.role_name = userDetailswith1ands[2].split("=")[1];
        this.contact_details.bank_details.bank_id = userDetailswith1ands[3].split("=")[1];
        this.contact_details.bank_details.bank_name = userDetailswith1ands[4].split("=")[1];
      }

      if (can_proceed == false) {
        this.Stepper = 8;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something went wrong",
        };
      } else {
        this.Stepper = 7;
        // const userAgent = navigator.userAgent;
        // this.userAgent = navigator.userAgent;
        // if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
        //   let sid = this.$route.params.InvitedMemberDetails;
        //   document.location = "com.mobil80.bankxchange://INVITEUSER/" + sid;
        // } else {
        this.Stepper = 1;
        // }
        return;
      }
    },

    async validateStepperOneMethod(type) {
      switch (type) {
        case "VERIFY_EMAIL":
          const { valid } = await this.$refs.form1.validate();
          if (valid) {
            // if (this.user_details.user_create_password == this.user_details.user_confirm_password) {
            this.loadingStepper1 = true;
            // let DomainBanks = [];
            // this.GetAllBanksList.map((item) => {
            //   if (this.contact_details.bank_details.bank_id === item.bank_id) {
            //     DomainBanks = item.bank_domains;
            //   }
            // });
            // if (DomainBanks.includes(this.user_details.user_email_id.split("@")[1])) {
            let result = await this.GetVericationCodeForUserSignInMethod(this.user_details.user_email_id);
            if (result.status == "SUCCESS") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: result.status_message,
              };
              this.is_otp_enabled = true;
              this.user_session = result.user_session;
              // this.Stepper = 2;
              this.loadingStepper1 = false;
            } else if (result.status === "ERROR") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: result.error_message,
              };
              this.loadingStepper1 = false;
            }
            // } else {
            //   this.loadingStepper1 = false;
            //   this.SnackBarComponent = {
            //     SnackbarVmodel: true,
            //     SnackbarColor: "red",
            //     Top: true,
            //     SnackbarText: `${this.user_details.user_email_id} does not match the bank domain.!`,
            //   };
            // }
            // } else {
            //   if (this.user_details.user_create_password !== this.user_details.user_confirm_password) {
            //     this.SnackBarComponent = {
            //       SnackbarVmodel: true,
            //       SnackbarColor: "red",
            //       Top: true,
            //       SnackbarText: "Passwords do not match. Please ensure both passwords are identical.",
            //     };
            //   }
            // }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Please enter password.",
            };
          }
          this.loadingStepper1 = false;
          break;
        case "VERIFY_OTP":
          if (this.user_details.verification_code !== "") {
            this.loadingStepper1 = true;
            let VerifyOtpResult = await this.GetCodeValidationMethod(this.user_session, this.user_details.verification_code);
            console.log("VerifyOtpResult", VerifyOtpResult);
            if (VerifyOtpResult.status === "SUCCESS") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: VerifyOtpResult.status_message,
              };

              let userExistsResult = await this.GetUserStatusMethod(this.user_details.user_email_id);
              if (userExistsResult && userExistsResult.Status == 1) {
                this.Stepper = 2;
              } else {
                if (userExistsResult == "ERROR") {
                  this.Stepper = 1;
                } else {
                  this.Stepper = 6;
                }
              }
              this.loadingStepper1 = false;
            } else if (VerifyOtpResult.error === "ERROR") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: VerifyOtpResult.status_message,
              };
            }
            this.loadingStepper1 = false;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Please enter verification code",
            };
          }
          break;
      }
    },
    async validateStepperTwoMethod() {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        if (this.user_details.user_create_password == this.user_details.user_confirm_password) {
          this.loadingStepper2 = true;
          this.Stepper = 3;
          this.loadingActivatingAccount = true;
          setTimeout(() => {
            this.loadingStepper2 = false;
            this.loadingActivatingAccount = false;
            this.contact_details.role_name = this.user_details.role_name;
            this.contact_details.role_id = this.user_details.role_id;
            this.Stepper = 4;
          }, 2000);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Passwords do not match. Please ensure both passwords are identical.",
          };
        }
      }
    },
    async validateStepperFourMethod() {
      if (this.contact_details.bank_details && this.contact_details.bank_details.bank_id) {
        const { valid } = await this.$refs.form4.validate();
        if (valid) {
          if (this.user_details.user_email_id != this.contact_details.executive_email_address) {
            this.Stepper = 5;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "User & Assistant Email ID's are same",
            };
          }
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please select Bank",
        };
      }
    },
    async validateStepperFiveMethod() {
      this.loadingStepper6 = true;
      this.user_details.user_confirm_password = btoa(this.user_details.user_confirm_password);
      let result = await this.GetActivateUserMethod(this.user_details, this.contact_details, this.ContactSettingsValue);
      if (result && result.status == "Success" && !result.errorType) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: result.status_message,
        };
        await Auth.signOut({ global: true });
        // this.signInMethod(sign_in);
        this.Stepper = 6;
        this.loadingStepper6 = false;
      } else {
        this.loadingStepper6 = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: result.errors[0].message,
        };
      }
      this.loadingStepper6 = false;
    },
    async validateStepperSixMethod() {
      window.location.replace(
        window.location.hostname === process.env.VUE_APP_DEV_HOST_NAME
          ? process.env.VUE_APP_DEV_ACCESS_LINK
          : window.location.hostname === process.env.VUE_APP_TEST_HOST_NAME
          ? process.env.VUE_APP_TEST_ACCESS_LINK
          : window.location.hostname === process.env.VUE_APP_UAT_HOST_NAME
          ? process.env.VUE_APP_UAT_ACCESS_LINK
          : process.env.VUE_APP_LIVE_ACCESS_LINK
      );
      // this.loadingStepper6 = true;
      // let result = await this.GetActivateUserMethod(
      //   this.user_details,
      //   this.contact_details,
      //   this.ContactSettingsValue
      // );
      // if (result.data && result.data.status == "Success") {
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "green",
      //     Top: true,
      //     SnackbarText: result.data.status_message,
      //   };

      //   let sign_in = {
      //     user_email_id: this.user_details.user_email_id,
      //     user_password: this.user_details.user_create_password,
      //   };
      //   this.signInMethod(sign_in);
      // }
      // this.loadingStepper6 = false;
    },
    async signInMethod(sign_in) {
      try {
        this.loadingStepper6 = true;
        this.loadingSignIn = true;
        this.qrCodeRequired = false;
        this.userObject = await Auth.signIn(sign_in.user_email_id, sign_in.user_password);
        this.$store.commit("SET_USEREMAIL", sign_in.user_email_id);
        if (this.userObject.challengeName === "MFA_SETUP") {
          const res = await Auth.setupTOTP(this.userObject);
          this.qrCodeURL = "otpauth://totp/AWSCognito:" + sign_in.user_email_id + "?secret=" + res + "&issuer=Cognito";
          this.qrCodeRequired = true;
        } else if (this.userObject.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.qrCodeRequired = false;
        } else {
          this.pushToHomeRouteMethod();
        }
        this.loadingSignIn = false;
      } catch (error) {
        this.loadingStepper6 = false;
        this.loadingSignIn = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    pushToHomeRouteMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      setTimeout(() => {
        this.loadingStepper5 = false;
        this.$router.push("/LandingPage");
      }, 1000);
    },
  },
};
</script>

<style></style>
