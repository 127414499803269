import { API, graphqlOperation } from "aws-amplify";
import { activateUser } from "@/graphql/mutations.js";
export const GetActivateUser = {
  data() {
    return {
      GetAllBanksList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async GetActivateUserMethod(user_details, contact_details, ContactSettingsValue) {
      try {
        let result = await API.graphql(
          graphqlOperation(activateUser, {
            input: {
              password: user_details.user_confirm_password,
              user_first_name: contact_details.user_first_name,
              user_last_name: contact_details.user_last_name,
              bank_id: contact_details.bank_details.bank_id,
              bank_name: contact_details.bank_details.bank_name,
              role_id: contact_details.role_id,
              user_contact_number: contact_details.user_contact_number,
              office_contact_number: contact_details.office_contact_number && contact_details.office_contact_number != "" ? contact_details.office_contact_number : undefined,
              linkedin_profile_url: contact_details.linkedin_profile_url != "" && contact_details.linkedin_profile_url != null ? contact_details.linkedin_profile_url : undefined,
              address_line_1: contact_details.address_line_1,
              address_line_2: contact_details.address_line_2 != "" && contact_details.address_line_2 ? contact_details.address_line_2 : undefined,
              city: contact_details.city,
              zipcode: contact_details.zipcode,
              state: contact_details.state,
              executive_first_name: contact_details.executive_first_name != "" && contact_details.executive_first_name ? contact_details.executive_first_name : undefined,
              executive_last_name: contact_details.executive_last_name != "" && contact_details.executive_last_name ? contact_details.executive_last_name : undefined,

              executive_email_id: contact_details.executive_email_address != "" && contact_details.executive_email_address ? contact_details.executive_email_address : undefined,

              preference_business_email_id: ContactSettingsValue.IsBusinessEmailAddress,
              preference_user_contact_number: ContactSettingsValue.IsBusinessMobilePhone,
              preference_office_contact_number: ContactSettingsValue.IsBusinessOfficePhone,
              preference_bankxchange_chat: ContactSettingsValue.IsBankXchangeChatEnable,
            },
          })
        );
        let ResultObj = JSON.parse(result.data.ActivateUser);
        console.log("ResultOnj", ResultObj);
        return ResultObj;
      } catch (error) {
        console.log("Check The Error", error);
        return "ERROR";
      }
    },
  },
};
