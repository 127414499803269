// import axios from "axios";
import { Auth } from "aws-amplify";
export const GetVericationCodeForUserSignIn = {
  data: () => ({}),
  methods: {
    async GetVericationCodeForUserSignInMethod(user_email_id) {
      try {
        let user_auth_session = await Auth.signIn(user_email_id);
        console.log("user_auth_session", user_auth_session);
        if (user_auth_session.challengeName === "CUSTOM_CHALLENGE") {
          return {
            status: "SUCCESS",
            user_session: user_auth_session,
            status_message: "OTP sent successful",
          };
        }
      } catch (error) {
        console.log("Error", error);
        return {
          status: "ERROR",
          error_message: error,
        };
      }
    },
  },
};
