<template>
  <v-app :style="{ background: customBackgroundColor }">
    <v-main>
      <TermsAndCondtions :dialogForTermsAndConditions="dialogForTermsAndConditions" @clicked="dialogForTermsAndConditions = false" />

      <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />

      <v-row no-gutters class="fill-height" align="center" justify="center" :style="{ height: `${VuetifyObj.height}px` }">
        <v-col cols="12" sm="12" md="12" xs="12" align="center" justify="center">
          <v-card-text>
            <v-card flat max-width="500px" v-if="current_view == 'CARD'" class="signUpPageCard">
              <v-window v-model="Stepper">
                <v-window-item :value="1">
                  <v-card class="elevation-0 text-left">
                    <div class="fontStyleVariant4">Step 1 / 2</div>
                    <div class="fontStyleVariant1 mt-2">Create your</div>
                    <div class="fontStyleVariant1">bankXchange account</div>
                    <div class="fontStyleVariant2 mt-2">Please create an account login in order to get started.</div>
                    <div class="gapVariant1"></div>
                    <v-form ref="form1">
                      <!-- <div class="fontStyleVariant2 mb-2">
                        Bank Name
                        <span class="red--text">*</span>
                      </div>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        readonly
                        filled
                        height="48px"
                        class="fontFamilyInter"
                        v-model="user_details.bank_name"
                        :rules="[(v) => !!v || 'Required']"
                      ></v-text-field>

                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <div class="fontStyleVariant2 mb-2">
                            First Name
                            <span class="red--text">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            height="48px"
                            class="fontFamilyInter"
                            v-model="user_details.executive_first_name"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" class="pl-2">
                          <div class="fontStyleVariant2 mb-2">
                            Last Name
                            <span class="red--text">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            height="48px"
                            class="fontFamilyInter"
                            v-model="user_details.executive_last_name"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </v-col>
                      </v-row> -->

                      <div class="fontStyleVariant2 mb-2">
                        Business Email Address
                        <span class="red--text">*</span>
                      </div>
                      <v-text-field
                        density="compact"
                        filled
                        variant="outlined"
                        readonly
                        disabled
                        v-model="user_details.executive_email_id"
                        :rules="[(v) => !!v || 'Required']"
                        height="48px"
                        class="fontFamilyInter"
                      ></v-text-field>
                      <div class="fontStyleVariant2 mb-2" v-if="is_otp_enabled">Verification code</div>
                      <v-otp-input
                        v-if="is_otp_enabled"
                        :length="6"
                        class="OTPField fontFamilyInter"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 6) || (v && v.length <= 6) || 'Password Must Have Min. 6 characters',
                          (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                        ]"
                        v-model="user_details.verification_code"
                      ></v-otp-input>
                      <!-- <div class="fontStyleVariant2 mb-2">Alternate Email Address</div>
                            <v-text-field
                              density="compact"
                              variant="outlined"
                              v-model="user_details.executive_alternate_email_id"
                              :rules="[
                                (v) => !!v || 'Required',
                                (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid Email',
                              ]"
                              height="48px"
                              class="fontFamilyInter"
                            ></v-text-field> -->

                      <!--  -->
                    </v-form>
                    <!-- <v-card-actions class="pa-0">
                      <v-checkbox density="compact" class="pa-0 ma-0" v-model="agree_terms_and_condtions"> </v-checkbox>
                      <div class="loginCardTextVariant4 mt-n5">
                        By checking this box, you agree to the
                        <span @click.stop="dialogForTermsAndConditions = true" class="blueColorVariant1--text FontUnderline cursorPointer">Terms & Conditions </span>
                      </div>
                    </v-card-actions> -->
                    <v-card-actions v-if="is_otp_enabled">
                      <div>
                        Didn't receive the OTP? Click here to
                        <span><v-btn text color="primary" small class="text-capitalize TextUnderline ml-n2" @click="validateStepperOneMethod('VERIFY_EMAIL')">Resend OTP</v-btn></span>
                      </div>
                    </v-card-actions>
                    <v-card-actions class="px-0 mx-0">
                      <v-btn dark depressed :loading="loadingStepper1" @click="validateStepperOneMethod(is_otp_enabled ? 'VERIFY_OTP' : 'VERIFY_EMAIL')" class="text-capitalize buttonClassVariant1">
                        {{ is_otp_enabled ? "Verify OTP" : "Verify Email" }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card class="elevation-0" align="left">
                    <div class="fontStyleVariant4">Step 2 / 2</div>
                    <div class="fontStyleVariant1 mt-2">Please confirm your information</div>
                    <div class="fontStyleVariant2 mt-2">We require two-factor authentication for enhanced security</div>
                    <div class="gapVariant2"></div>
                    <v-form ref="form2">
                      <div class="fontStyleVariant2 mb-2">
                        Bank Name
                        <span class="red--text">*</span>
                      </div>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        readonly
                        filled
                        height="48px"
                        class="fontFamilyInter"
                        disabled
                        v-model="user_details.bank_name"
                        :rules="[(v) => !!v || 'Required']"
                      ></v-text-field>

                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <div class="fontStyleVariant2 mb-2">
                            First Name
                            <span class="red--text">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            height="48px"
                            class="fontFamilyInter"
                            v-model="user_details.executive_first_name"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" class="pl-2">
                          <div class="fontStyleVariant2 mb-2">
                            Last Name
                            <span class="red--text">*</span>
                          </div>
                          <v-text-field
                            density="compact"
                            variant="outlined"
                            height="48px"
                            class="fontFamilyInter"
                            v-model="user_details.executive_last_name"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div class="fontStyleVariant2 mb-2">Create Password <span class="red--text">*</span></div>

                      <v-text-field
                        density="compact"
                        variant="outlined"
                        :type="isPwdLoginCreate ? 'text' : 'password'"
                        v-model="user_details.user_create_password"
                        @click:append-inner="isPwdLoginCreate = !isPwdLoginCreate"
                        :append-inner-icon="isPwdLoginCreate ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => !!v || 'Password is required',
                          (v) => (v && v.length >= 8) || 'Password must be at least 8 characters long',
                          (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
                          (v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
                          (v) => /[^a-zA-Z0-9]/.test(v) || 'Password must contain at least one special character',
                          (v) => /\d/.test(v) || 'Password must contain at least one number',
                        ]"
                        placeholder="8+ Char. incl. upper & lower, numeric & special"
                        height="48px"
                        class="fontFamilyInter"
                      ></v-text-field>
                      <div class="fontStyleVariant2 mb-2">Confirm Password <span class="red--text">*</span></div>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        :type="isPwdLoginConfirm ? 'text' : 'password'"
                        v-model="user_details.user_confirm_password"
                        @click:append-inner="isPwdLoginConfirm = !isPwdLoginConfirm"
                        :append-inner-icon="isPwdLoginConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => !!v || 'Password is required',
                          (v) => (v && v.length >= 8) || 'Password must be at least 8 characters long',
                          (v) => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
                          (v) => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
                          (v) => /[^a-zA-Z0-9]/.test(v) || 'Password must contain at least one special character',
                          (v) => /\d/.test(v) || 'Password must contain at least one number',
                        ]"
                        placeholder="8+ Char. incl. upper & lower, numeric & special"
                        height="48px"
                        class="fontFamilyInter AcceptInvitation"
                      ></v-text-field>
                    </v-form>
                    <v-btn dark depressed :loading="loadingStepper2" @click="validateStepperTwoMethod()" class="text-capitalize buttonClassVariant1"> Submit </v-btn>
                  </v-card>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="pa-0 text-left">
                    <v-card v-if="loadingActivatingAccount">
                      <div class="fontStyleVariant4">Step 2 / 3</div>
                      <div class="fontStyleVariant1">One moment please</div>
                      <div class="gapVariant3"></div>
                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-card flat class="loadingInsideCard" align="center">
                            <v-progress-circular indeterminate color="primary" size="100" width="10" class="mt-6"></v-progress-circular>
                            <div class="fontStyleVariant3 mb-2">Activating your account.</div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                  <v-card class="elevation-0 text-left">
                    <div class="fontStyleVariant4 mb-2">Finished</div>
                    <div>
                      <span class="fontStyleVariant8">Congratulations, </span>
                      <span class="fontStyleVariant1"> {{ user_details.executive_first_name }}! </span>
                    </div>

                    <div class="fontStyleVariant2 mt-6">
                      You have successfully registered with bankXchange and you are now ready to perform actions on behalf of
                      {{ user_details.user_first_name }}. Thank you for joining our portal.
                    </div>
                    <v-card-actions>
                      <div dark depressed @click="validateStepperSixMethod()" class="white--text buttonClassVariant1 cursorPointer pa-6 ml-n2 mt-5">Continue To bankXchange</div>
                    </v-card-actions>
                    <div class="fontStyleVariant2 mt-8">You will also receive an email inviting you to test bankXchange mobile application using TestFlight.</div>
                    <div class="fontStyleVariant5 mt-8">
                      Should you have any questions, please reach out to the <b>MBCA bankXchange</b> team at <b><u>help@bankxchange.com</u></b
                      >.
                    </div>
                  </v-card>
                </v-window-item>
                <v-window-item :value="7">
                  <v-card-text class="pa-0 text-left">
                    <v-card>
                      <div class="fontStyleVariant1">One moment please</div>
                      <div class="gapVariant3"></div>
                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-card flat class="loadingInsideCard" align="center">
                            <v-progress-circular indeterminate color="primary" size="100" width="10" class="mt-6"></v-progress-circular>
                            <div class="fontStyleVariant3 mb-2">Fetching account details</div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="8">
                  <v-card-text class="pa-0 text-left">
                    <v-card>
                      <div class="fontStyleVariant1 text-center">Something went wrong..!</div>
                      <div class="gapVariant3"></div>
                      <!-- <v-row no-gutters>
                              <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card flat class="loadingInsideCard" align="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="100"
                                    width="10"
                                    class="mt-6"
                                  ></v-progress-circular>
                                  <div class="fontStyleVariant3 mb-2">Fetching account details</div>
                                </v-card>
                              </v-col>
                            </v-row> -->
                    </v-card>
                  </v-card-text>
                </v-window-item>
              </v-window>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import stateList from "@/JsonFiles/stateList.json";
import { Auth } from "aws-amplify";
import { GetAPIKey } from "@/mixins/RestAPIs/GetAPIKey.js";
import { ActivateImpersonateUser } from "@/mixins/RestAPIs/ActivateImpersonateUser.js";
import { GetVericationCodeForUserSignIn } from "@/mixins/RestAPIs/GetVericationCodeForUserSignIn.js";
import { GetCodeValidation } from "@/mixins/RestAPIs/GetCodeValidation";
import { GetActivateUser } from "@/mixins/RestAPIs/GetActivateUser";
import { GetUserStatus } from "@/mixins/RestAPIs/GetUserStatus.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { useDisplay } from "vuetify";
import TermsAndCondtions from "@/components/Extras/TermsAndCondtions.vue";
export default {
  mixins: [GetAPIKey,  GetUserStatus, GetActivateUser, GetCodeValidation, ActivateImpersonateUser, GetVericationCodeForUserSignIn],
  components: {
    Snackbar,
    TermsAndCondtions,
  },
  data: () => ({
    Stepper: 1,
    contact_count: 0,

    userAgent: "",
    customBackgroundColor: "#f0f8ff",
    current_view: "CARD",

    loadingStepper1: false,
    loadingStepper2: false,
    loadingStepper6: false,
    isPwdLoginConfirm: false,
    isPwdLoginCreate: false,
    loadingActivatingAccount: false,
    EnableExecutiveAssistant: false,
    renderComp: true,
    agree_terms_and_condtions: false,
    dialogForTermsAndConditions: false,
    is_otp_enabled: false,

    contactSettingsValue: {
      IsBusinessEmailAddress: true,
      IsBusinessMobilePhone: true,
      IsBusinessOfficePhone: true,
      IsBankXchangeChatEnable: true,
    },
    user_details: {
      role_id: "",
      bank_id: "",
      bank_name: "",
      user_email_id: "",
      user_first_name: "",
      executive_first_name: "",
      executive_last_name: "",
      executive_email_id: "",
      executive_alternate_email_id: "",
      user_create_password: "",
      user_confirm_password: "",
      verification_code: "",
    },
    contact_details: {
      executive_first_name: "",
      bank_details: {},
      role_name: "",
      role_id: "",
      executive_last_name: "",
      user_contact_number: "",
      office_contact_number: "",
      linkedin_profile_url: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      zipcode: "",
    },
    SnackBarComponent: {},
    VuetifyObj: {},
    user_session: {},

    stateList: [],
    Profile_details: [],
    ListBanks: ["ListBanks", "FewBanks"],
    ContactSettings: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  }),
  watch: {},
  computed: {
    IsValidated() {
      return this.contact_count === this.Profile_details.length;
    },
  },
  async mounted() {
    this.stateList = stateList;
    this.VuetifyObj = useDisplay();
    setTimeout(() => {
      this.user_details.user_create_password = "";
      this.user_details.user_confirm_password = "";
      if (this.$refs.form1) {
        this.$refs.form1.resetValidation();
      }
    }, 300);
    this.GetUserEmailMethod();
  },
  methods: {
    async validateStepperSixMethod() {
      window.location.replace(
        window.location.hostname === process.env.VUE_APP_DEV_HOST_NAME
          ? process.env.VUE_APP_DEV_ACCESS_LINK
          : window.location.hostname === process.env.VUE_APP_TEST_HOST_NAME
          ? process.env.VUE_APP_TEST_ACCESS_LINK
          : window.location.hostname === process.env.VUE_APP_UAT_HOST_NAME
          ? process.env.VUE_APP_UAT_ACCESS_LINK
          : process.env.VUE_APP_LIVE_ACCESS_LINK
      );
    },
    FormattingOfficePhoneNumber() {
      this.contact_details.office_contact_number = this.contact_details.office_contact_number.replace(/[^\d]/g, "");
      if (this.contact_details.office_contact_number.length > 3) {
        this.contact_details.office_contact_number = `(${this.contact_details.office_contact_number.slice(0, 3)})` + " " + this.contact_details.office_contact_number.slice(3);
      }
      if (this.contact_details.office_contact_number.length > 8) {
        this.contact_details.office_contact_number = this.contact_details.office_contact_number.slice(0, 9) + "-" + this.contact_details.office_contact_number.slice(9);
      }
    },
    FormattingMobilePhoneNumber() {
      this.contact_details.user_contact_number = this.contact_details.user_contact_number.replace(/[^\d]/g, "");
      if (this.contact_details.user_contact_number.length > 3) {
        this.contact_details.user_contact_number = `(${this.contact_details.user_contact_number.slice(0, 3)})` + " " + this.contact_details.user_contact_number.slice(3);
      }
      if (this.contact_details.user_contact_number.length > 8) {
        this.contact_details.user_contact_number = this.contact_details.user_contact_number.slice(0, 9) + "-" + this.contact_details.user_contact_number.slice(9);
      }
    },
    async onEnteringExecutingEmailIDExistsMethod() {
      // if (this.contact_details.executive_email_address.length >= 9) {
      //   let userExistsResult = await this.GetUserStatusMethod(this.contact_details.executive_email_address);
      //   if (userExistsResult.Status != 1) {
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       Top: true,
      //       SnackbarText: "This Email Address is already registered!!.",
      //     };
      //     this.contact_details.executive_email_address = "";
      //   }
      // }
    },

    async GetUserEmailMethod() {
      // let userDetails = atob(this.$route.params.InvitedEADetails).split("&");
      let userDetailswith3ands = atob(this.$route.params.InvitedEADetails).split("&&&");
      let userDetailswith1ands = atob(this.$route.params.InvitedEADetails).split("&");
      let can_proceed = false;
      if (userDetailswith3ands.length === 8) {
        can_proceed = true;
        this.user_details.executive_email_id = userDetailswith3ands[0].split("=")[1];
        this.user_details.executive_first_name = userDetailswith3ands[1].split("=")[1];
        this.user_details.executive_last_name = userDetailswith3ands[2].split("=")[1];
        this.user_details.user_email_id = userDetailswith3ands[3].split("=")[1];
        this.user_details.bank_id = userDetailswith3ands[4].split("=")[1];
        this.user_details.bank_name = userDetailswith3ands[5].split("=")[1];
        this.user_details.role_id = userDetailswith3ands[6].split("=")[1];
        this.user_details.user_first_name = userDetailswith3ands[7].split("=")[1];
      }
      if (userDetailswith1ands.length === 8) {
        can_proceed = true;
        this.user_details.executive_email_id = userDetailswith1ands[0].split("=")[1];
        this.user_details.executive_first_name = userDetailswith1ands[1].split("=")[1];
        this.user_details.executive_last_name = userDetailswith1ands[2].split("=")[1];
        this.user_details.user_email_id = userDetailswith1ands[3].split("=")[1];
        this.user_details.bank_id = userDetailswith1ands[4].split("=")[1];
        this.user_details.bank_name = userDetailswith1ands[5].split("=")[1];
        this.user_details.role_id = userDetailswith1ands[6].split("=")[1];
        this.user_details.user_first_name = userDetailswith1ands[7].split("=")[1];
      }
      if (can_proceed === false) {
        this.Stepper = 8;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something went wrong",
        };
      } else {
        this.Stepper = 1;
        // let userExistsResult = await this.GetUserStatusMethod(this.user_details.executive_email_id);
        // if (userExistsResult && userExistsResult.Status == 1) {
        //   this.Stepper = 1;
        // } else {
        //   if (userExistsResult == "ERROR") {
        //     this.Stepper = 1;
        //   } else {
        //     this.Stepper = 6;
        //   }
        // }
      }
    },
    async validateStepperOneMethod(type) {
      switch (type) {
        case "VERIFY_EMAIL":
          const { valid } = await this.$refs.form1.validate();
          if (valid) {
            // if (this.user_details.user_create_password == this.user_details.user_confirm_password) {
            this.loadingStepper1 = true;
            // let DomainBanks = [];
            // this.GetAllBanksList.map((item) => {
            //   if (this.contact_details.bank_details.bank_id === item.bank_id) {
            //     DomainBanks = item.bank_domains;
            //   }
            // });
            // if (DomainBanks.includes(this.user_details.user_email_id.split("@")[1])) {
            let result = await this.GetVericationCodeForUserSignInMethod(this.user_details.executive_email_id);
            if (result.status == "SUCCESS") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: result.status_message,
              };
              this.is_otp_enabled = true;
              this.user_session = result.user_session;
              // this.Stepper = 2;
              this.loadingStepper1 = false;
            } else if (result.status === "ERROR") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: result.error_message,
              };
              this.loadingStepper1 = false;
            }
            // } else {
            //   this.loadingStepper1 = false;
            //   this.SnackBarComponent = {
            //     SnackbarVmodel: true,
            //     SnackbarColor: "red",
            //     Top: true,
            //     SnackbarText: `${this.user_details.user_email_id} does not match the bank domain.!`,
            //   };
            // }
            // } else {
            //   if (this.user_details.user_create_password !== this.user_details.user_confirm_password) {
            //     this.SnackBarComponent = {
            //       SnackbarVmodel: true,
            //       SnackbarColor: "red",
            //       Top: true,
            //       SnackbarText: "Passwords do not match. Please ensure both passwords are identical.",
            //     };
            //   }
            // }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Please enter password.",
            };
          }
          this.loadingStepper1 = false;
          break;
        case "VERIFY_OTP":
          if (this.user_details.verification_code !== "") {
            this.loadingStepper1 = true;
            let VerifyOtpResult = await this.GetCodeValidationMethod(this.user_session, this.user_details.verification_code);
            console.log("VerifyOtpResult", VerifyOtpResult);
            if (VerifyOtpResult.status === "SUCCESS") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: VerifyOtpResult.status_message,
              };

              let userExistsResult = await this.GetUserStatusMethod(this.user_details.user_email_id);
              if (userExistsResult && userExistsResult.Status == 1) {
                this.Stepper = 2;
              } else {
                if (userExistsResult == "ERROR") {
                  this.Stepper = 1;
                } else {
                  this.Stepper = 6;
                }
              }
              this.loadingStepper1 = false;
            } else if (VerifyOtpResult.error === "ERROR") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: VerifyOtpResult.status_message,
              };
            }
            this.loadingStepper1 = false;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Please enter verification code",
            };
          }
          break;
      }
    },
    async validateStepperTwoMethod() {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        this.loadingStepper2 = true;
        if (this.user_details.user_create_password === this.user_details.user_confirm_password) {
          let result = await this.ActivateImpersonateUserMethod(this.user_details);
          console.log("Result",result)
          if (result.status === "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: result.status_message,
            };
            setTimeout(() => {
              this.loadingActivatingAccount = false;
              this.Stepper = 6;
            }, 2000);
          } else if (result.status === "ERROR") {
            this.loadingStepper2 = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.status_message.errors[0].message,
            };
            this.loadingStepper2 = false;
            this.loadingActivatingAccount = false;
            this.Stepper = 2;
          }
        } else {
          this.loadingStepper2 = true;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Passwords do not match. Please ensure both passwords are identical.",
          };
        }
        //   let VerifyOTPresult = await this.GetCodeValidationMethod(this.user_session, this.user_details.verification_code);
        //   if (VerifyOTPresult.status === "SUCCESS") {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "green",
        //       Top: true,
        //       SnackbarText: VerifyOTPresult.status_message,
        //     };
        //     this.Stepper = 3;
        //     this.loadingActivatingAccount = true;
        //     this.loadingStepper2 = false;
        //     this.user_details.user_confirm_password = btoa(this.user_details.user_confirm_password);
        //     let result = await this.ActivateImpersonateUserMethod(this.user_details);
        //     if (result.status === "Success") {
        //       this.SnackBarComponent = {
        //         SnackbarVmodel: true,
        //         SnackbarColor: "green",
        //         Top: true,
        //         SnackbarText: result.status_message,
        //       };
        //       setTimeout(() => {
        //         this.loadingActivatingAccount = false;
        //         this.Stepper = 6;
        //       }, 2000);
        //     } else if (result.status === "ERROR") {
        //       this.SnackBarComponent = {
        //         SnackbarVmodel: true,
        //         SnackbarColor: "red",
        //         Top: true,
        //         SnackbarText: result.status_message.error[0].message,
        //       };
        //       this.loadingStepper2 = false;
        //       this.loadingActivatingAccount = false;
        //       this.Stepper = 2;
        //     } else if (result.data.errorType === "TypeError") {
        //       this.SnackBarComponent = {
        //         SnackbarVmodel: true,
        //         SnackbarColor: "red",
        //         Top: true,
        //         SnackbarText: result.data.errorMessage,
        //       };
        //       this.loadingStepper2 = false;
        //       this.loadingActivatingAccount = false;
        //       this.Stepper = 2;
        //     }
        //   } else if (VerifyOTPresult.data.errorType === "Error") {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: VerifyOTPresult.data.errorMessage,
        //     };
        //   }
        //   this.loadingStepper2 = false;
        // } else {
        //   this.SnackBarComponent = {
        //     SnackbarVmodel: true,
        //     SnackbarColor: "red",
        //     Top: true,
        //     SnackbarText: "Please fill in all mandatory fields before proceeding.",
        //   };
      }
    },
    async validateStepperFourMethod() {
      if (this.contact_details.bank_details && this.contact_details.bank_details.bank_id) {
        const { valid } = await this.$refs.form4.validate();
        if (valid) {
          if (this.user_details.executive_email_id != this.contact_details.executive_email_address) {
            this.Stepper = 5;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "User & Assistant Email ID's are same",
            };
          }
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please select Bank",
        };
      }
    },
    async validateStepperFiveMethod() {
      this.loadingStepper6 = true;
      this.user_details.user_confirm_password = btoa(this.user_details.user_confirm_password);
      let result = await this.GetActivateUserMethod(this.user_details, this.contact_details, this.contactSettingsValue);
      if (result.data && result.data.status == "Success" && !result.data.errorType) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: result.data.status_message,
        };
        this.Stepper = 6;
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: result.data.errorMessage,
        };
      }
      this.loadingStepper6 = false;
    },
    // async validateStepperSixMethod() {
    //   this.loadingStepper6 = true;
    //   let result = await this.GetActivateUserMethod(
    //     this.user_details,
    //     this.contact_details,
    //     this.contactSettingsValue
    //   );
    //   if (result.data && result.data.status == "Success") {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "green",
    //       Top: true,
    //       SnackbarText: result.data.status_message,
    //     };

    //     let sign_in = {
    //       executive_email_id: this.user_details.executive_email_id,
    //       user_password: this.user_details.user_create_password,
    //     };
    //     this.signInMethod(sign_in);
    //   }
    //   this.loadingStepper6 = false;
    // },
    async signInMethod(sign_in) {
      try {
        this.loadingStepper6 = true;
        this.loadingSignIn = true;
        this.qrCodeRequired = false;
        this.userObject = await Auth.signIn(sign_in.executive_email_id, sign_in.user_password);
        this.$store.commit("SET_USEREMAIL", sign_in.executive_email_id);
        if (this.userObject.challengeName === "MFA_SETUP") {
          const res = await Auth.setupTOTP(this.userObject);
          this.qrCodeURL = "otpauth://totp/AWSCognito:" + sign_in.executive_email_id + "?secret=" + res + "&issuer=Cognito";
          this.qrCodeRequired = true;
        } else if (this.userObject.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.qrCodeRequired = false;
        } else {
          this.pushToHomeRouteMethod();
        }
        this.loadingSignIn = false;
      } catch (error) {
        this.loadingStepper6 = false;
        this.loadingSignIn = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    pushToHomeRouteMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      setTimeout(() => {
        this.loadingStepper5 = false;
        this.$router.push("/LandingPage");
      }, 1000);
    },
  },
};
</script>

<style></style>
