import { API, graphqlOperation } from "aws-amplify";
import { activateImpersonateUser } from "@/graphql/mutations.js";
export const ActivateImpersonateUser = {
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {
    async ActivateImpersonateUserMethod(user_details) {
      try {
        let result = await API.graphql(
          graphqlOperation(activateImpersonateUser, {
            input: {
              executive_first_name: user_details.executive_first_name,
              executive_last_name: user_details.executive_last_name,
              // executive_alternative_email_id: user_details.executive_email_id,
              password: user_details.user_confirm_password,
            },
          })
        );
        let ResultObj = JSON.parse(result.data.ActivateImpersonateUser);
        return ResultObj;
      } catch (error) {
        console.log("Check The Error", error);
        return {
          status: "ERROR",
          status_message: error,
        };
      }
    },
  },
};
