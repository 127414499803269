import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const getDefaultState = () => ({
  EmailID: "",
  TokenDetails: {},
  GetCurrentUser: {},
  changeBgColor: false,
  DataStore: [],
  GetAllMyOneToOneDiscussionsList: [],
});
export default createStore({
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_bg_color: (state) => {
      return state.changeBgColor;
    },
    get_token_details: (state) => {
      return state.TokenDetails;
    },
    get_stored_data: (state) => {
      return state.DataStore;
    },
    get_all_my_one_to_one_discussion_list: (state) => {
      return state.GetAllMyOneToOneDiscussionsList;
    },
  },
  mutations: {
    SET_USEREMAIL(state, EmailID) {
      state.EmailID = EmailID;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_BG_COLOR(state, changeBgColor) {
      state.changeBgColor = changeBgColor;
    },
    SET_TOKEN_DETAILS(state, TokenDetails) {
      state.TokenDetails = TokenDetails;
    },
    SET_ONE_TO_ONE_DISCUSSION_LIST(state, GetAllMyOneToOneDiscussionsList) {
      state.GetAllMyOneToOneDiscussionsList = GetAllMyOneToOneDiscussionsList;
    },
    SET_ALL_DATA_STORE_DETAILS(state, DataStore) {
      state.DataStore = DataStore;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    clearStore({ commit }) {
      commit("RESET_STATE");
    },
  },
  modules: {},
});

