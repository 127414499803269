import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
loadFonts();
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === process.env.VUE_APP_DEV_HOST_NAME || window.location.hostname === process.env.VUE_APP_LOCAL_HOST_NAME
        ? process.env.VUE_APP_DEV_USER_POOL_ID
        : window.location.hostname === process.env.VUE_APP_TEST_HOST_NAME
        ? process.env.VUE_APP_TEST_USER_POOL_ID
        : window.location.hostname === process.env.VUE_APP_UAT_HOST_NAME
        ? process.env.VUE_APP_UAT_USER_POOL_ID
        : process.env.VUE_APP_LIVE_USER_POOL_ID,
    region: process.env.VUE_APP_ACCESS_REGION,
    userPoolWebClientId:
      window.location.hostname === process.env.VUE_APP_DEV_HOST_NAME || window.location.hostname === process.env.VUE_APP_LOCAL_HOST_NAME
        ? process.env.VUE_APP_DEV_CLIENT_ID
        : window.location.hostname === process.env.VUE_APP_TEST_HOST_NAME
        ? process.env.VUE_APP_TEST_CLIENT_ID
        : window.location.hostname === process.env.VUE_APP_UAT_HOST_NAME
        ? process.env.VUE_APP_UAT_CLIENT_ID
        : process.env.VUE_APP_LIVE_CLIENT_ID,
  },
  API: {
    aws_project_region: process.env.VUE_APP_ACCESS_REGION,
    aws_appsync_graphqlEndpoint:
      window.location.hostname === process.env.VUE_APP_DEV_HOST_NAME || window.location.hostname === process.env.VUE_APP_LOCAL_HOST_NAME
        ? process.env.VUE_APP_DEV_END_POINT
        : window.location.hostname === process.env.VUE_APP_TEST_HOST_NAME
        ? process.env.VUE_APP_TEST_END_POINT
        : window.location.hostname === process.env.VUE_APP_UAT_HOST_NAME
        ? process.env.VUE_APP_UAT_END_POINT
        : process.env.VUE_APP_LIVE_END_POINT,
    aws_appsync_region: process.env.VUE_APP_ACCESS_REGION,
    aws_appsync_authenticationType: process.env.VUE_APP_AUTHENTICATION_TYPE,
  },
});

createApp(App).use(router).use(store).use(vuetify).mount("#app");
