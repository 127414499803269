import { API, graphqlOperation } from "aws-amplify";
import { getUserStatus } from "@/graphql/queries.js";
export const GetUserStatus = {
  data: () => ({}),
  methods: {
    async GetUserStatusMethod() {
      try {
        let result = await API.graphql(
          graphqlOperation(getUserStatus, {
            input: {},
          })
        );
        let ResultObj = JSON.parse(result.data.GetUserStatus);
        return ResultObj;
      } catch (error) {
        console.log("Check The Error", error);
        return "ERROR";
      }
    },
  },
};
