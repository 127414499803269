<template>
  <v-app class="AppBackgroundDesignVariant1">
    <dialogForTermsAndConditions v-if="dialogForTermsAndConditions" :dialogForTermsAndConditions="dialogForTermsAndConditions" @clicked="dialogForTermsAndConditions = false" />
    <v-card tile flat color="transparent" height="100%" :width="VuetifyObj.width" class="px-3">
      <Snackbar :SnackBarComponent="SnackBarComponent" />
      <!-- <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex> -->
      <v-row no-gutters align="center" justify="center" :style="{ height: `${VuetifyObj.height}px` }">
        <!-- <v-col cols="12" align="center">
          <v-img width="250" src="@/assets/bankXchange_newLogo.svg" />
        </v-col> -->
        <v-col cols="12" align="center" class="">
          <v-img width="250" src="@/assets/bankXchange_newLogo.svg" />
          <v-card flat :max-width="VuetifyObj.smAndDown ? GetUpdatedValueMethod(VuetifyObj.width) : 450" height="auto" class="CardVariant1 mt-12">
            <v-window v-model="loginStepper" :touch="false">
              <v-window-item :value="1">
                <div class="TextVariant1 text-left">Member Login</div>
                <div class="TextVariant2 mt-2 text-left">Please login with your credentials in order to get started.</div>
                <v-form ref="step1Form">
                  <div class="TextVariant3 text-left mt-6">Email Address:</div>
                  <v-text-field
                    class="TextFieldVariant1 TextVariant3 mt-1 borderVariant1 text-left"
                    height="44"
                    variant="outlined"
                    density="compact"
                    :rules="[(v) => !!v || 'Required', (v) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Invalid email address']"
                    v-model="Login.EmailId"
                    hide-details
                  ></v-text-field>
                  <v-card-actions class="justify-space-between ma-0 pa-0 mb-0 CustomizedCardActions2 mt-2">
                    <div class="TextVariant3 text-left">Password:</div>
                    <div class="TextVariant3 text-left">
                      <v-btn text x-small class="text-capitalize pa-0 ma-0 buttonVariant1 TextUnderlineVariant1" @click="loginStepper = 3">Forgot Password?</v-btn>
                    </div>
                  </v-card-actions>
                  <v-text-field
                    class="TextFieldVariant1 TextFieldVariant2 TextVariant3 borderVariant1 text-left"
                    height="44"
                    variant="outlined"
                    hide-details
                    density="compact"
                    type="password"
                    v-model="Login.password"
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field>
                  <!-- <v-card-actions class="pa-0 ma-0"> -->
                  <v-card flat color="blueColorVariant5" class="mt-7" height="42" :link="!Step1Loader ? true : false" @click="ValidateLoginMethod()">
                    <v-card-actions class="justify-space-between px-5">
                      <div class="mt-n2">{{ Step1Loader ? "Signing In" : "Login" }}</div>
                      <div v-if="!Step1Loader" class="mt-n2">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                      <div v-if="Step1Loader" class="newLoader3 mt-n2"></div>
                    </v-card-actions>
                  </v-card>
                  <!-- <v-btn class="text-capitalize BtnVariant1 FontSize14px" height="42" variant="flat" dark block color="blueColorVariant5" @click="ValidateLoginMethod()">
                  
                      <v-card-actions class="pa-0 ma-0 justify-space-between">
                        <div>{{ Step1Loader ? "Signing In" : "Login" }}</div>
                        <v-spacer />
                        <div v-if="!Step1Loader">
                          <v-icon>mdi-chevron-right</v-icon>
                        </div>
                        <div v-if="Step1Loader" class="newLoader3"></div>
                      </v-card-actions>
                    </v-btn> -->
                  <!-- </v-card-actions> -->
                </v-form>
                <div class="TextVariant4 text-left mt-8 cursorPointer" @click="dialogForTermsAndConditions = true">By continuing, you agree to the <span class="TextVariant5 text-decoration-underline">Terms & Conditions.</span></div>
              </v-window-item>
              <v-window-item :value="2">
                <div class="TextVariant1 text-left">Please enter the code sent to your email.</div>
                <div class="TextVariant2 text-left mt-2">An OTP code was sent to your registered email to {{ maskedEmail }}.</div>
                <v-form ref="Step2Form" class="mt-7">
                  <v-otp-input type="number" class="OTPField borderVariant1" v-model="Login.otp" :rules="[(v) => !!v || 'Required']"></v-otp-input>
                </v-form>
                <v-card flat color="blueColorVariant5" class="mt-7" height="42" :link="!Step2Loader ? true : false" @click="ValidateStep2OtpMethod()">
                  <v-card-actions class="justify-space-between px-5">
                    <div class="mt-n2">{{ Step2Loader ? "Verifying" : "Verify" }}</div>
                    <div v-if="!Step2Loader" class="mt-n2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </div>
                    <div v-if="Step2Loader" class="newLoader3 mt-n3"></div>
                  </v-card-actions>
                </v-card>
                <!-- <v-card-actions class="pa-0 ma-0">
                  <v-btn class="text-capitalize BtnVariant1 mt-6 FontSize14px" height="42" depressed block dark color="blueColorVariant5" @click="ValidateStep2OtpMethod()">
                    <v-card-actions class="pa-0 ma-0 justify-space-between">
                      <div>{{ Step2Loader ? "Verifying" : "Verify" }}</div>
                      <div v-if="!Step2Loader">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                      <div v-if="Step2Loader" class="newLoader3"></div>
                    </v-card-actions>
                  </v-btn>
                </v-card-actions> -->
                <div class="TextVariant4 text-left mt-8">
                  Didn’t receive a code?
                  <span class="TextVariant5 buttonVariant1 cursorPointer text-decoration-underline" :class="Step1Loader ? 'disabled-text ' : ''" @click="ValidateLoginMethod()">Resend</span>
                </div>
              </v-window-item>
              <v-window-item :value="3">
                <div class="TextVariant1 text-left">Forgot Password?</div>
                <div class="TextVariant2 text-left mt-2">Please enter the registered email address.</div>
                <v-form ref="Step3Form">
                  <div class="TextVariant3 text-left mt-6">Email Address:</div>
                  <v-text-field
                    class="TextFieldVariant1 TextVariant3 mt-1 borderVariant1"
                    height="44"
                    variant="outlined"
                    density="compact"
                    :rules="[(v) => !!v || 'Required', (v) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Invalid email address']"
                    v-model="ForgotPassword.EmailId"
                  ></v-text-field>
                  <v-card flat color="blueColorVariant5" class="" height="42" :link="!Step3Loader ? true : false" @click="ValidateStep3Method()">
                    <v-card-actions class="justify-space-between px-5">
                      <div class="mt-n2">{{ Step3Loader ? "Verifying" : "Next" }}</div>
                      <div v-if="!Step3Loader" class="mt-n2">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                      <div v-if="Step3Loader" class="newLoader3 mt-n2"></div>
                    </v-card-actions>
                  </v-card>
                  <!-- <v-card-actions class="pa-0 ma-0">
                    <v-btn class="text-capitalize BtnVariant1 FontSize14px" height="42" depressed block dark color="blueColorVariant5" @click="ValidateStep3Method()">
                      <v-card-actions class="pa-0 ma-0 justify-space-between">
                        <div>{{ Step3Loader ? "Verifying" : "Next" }}</div>
                        <div v-if="!Step3Loader">
                          <v-icon>mdi-chevron-right</v-icon>
                        </div>
                        <div v-if="Step3Loader" class="newLoader3"></div>
                      </v-card-actions>
                    </v-btn>
                  </v-card-actions> -->
                  <div class="TextVariant4 text-left mt-8"><span class="TextVariant5 cursorPointer TextUnderlineVariant1" @click="loginStepper = 1">Back to Sign In</span></div>
                </v-form>
              </v-window-item>
              <v-window-item :value="4">
                <div class="TextVariant1 text-left">Forgot Password?</div>
                <div class="TextVariant2 text-left mt-2">Enter the bankXchange Activation Code sent to your registered Email</div>
                <v-form ref="step4Form">
                  <div class="TextVariant3 text-left mt-4">bankXchange Activation Code:</div>
                  <v-text-field
                    class="TextFieldVariant1 TextVariant3 mt-1 borderVariant1 text-left"
                    height="44"
                    variant="outlined"
                    density="compact"
                    :rules="[(v) => !!v || 'Required', (v) => /^\d{1,6}$/.test(v) || 'Only numbers up to 6 digits are allowed']"
                    v-model="ForgotPassword.Activation_code"
                  ></v-text-field>
                  <div class="TextVariant3 text-left">Enter New Password:</div>
                  <v-text-field
                    class="TextFieldVariant1 TextFieldVariant2 TextVariant3 mt-1 borderVariant1 text-left"
                    height="44"
                    variant="outlined"
                    density="compact"
                    :type="show_new_password ? 'text' : 'password'"
                    v-model="ForgotPassword.new_password"
                    :rules="[(v) => !!v || 'Required']"
                  >
                    <template v-slot:append-inner>
                      <v-btn icon color="greyColorVariant8" variant="text" class="" @click="show_new_password = !show_new_password"
                        ><v-icon>{{ show_new_password ? "mdi-eye-off" : "mdi-eye" }}</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                </v-form>
                <v-card flat color="blueColorVariant5" class="mt-2" height="42" :link="!Step4Loader ? true : false" @click="ValidateStep4Method()">
                  <v-card-actions class="justify-space-between px-5">
                    <div class="mt-n2">{{ Step4Loader ? "Updating" : "Confirm" }}</div>
                    <div v-if="!Step4Loader" class="mt-n2">
                      <v-icon>mdi-chevron-right</v-icon>
                    </div>
                    <div v-if="Step4Loader" class="newLoader3 mt-n2"></div>
                  </v-card-actions>
                </v-card>
                <!-- <v-card-actions class="pa-0 ma-0">
                  <v-btn class="text-capitalize BtnVariant1 FontSize14px" height="42" depressed block dark color="blueColorVariant5" @click="ValidateStep4Method()">
                    <v-card-actions class="pa-0 ma-0 justify-space-between">
                      <div>{{ Step4Loader ? "Updating" : "Confirm" }}</div>
                      <div v-if="!Step4Loader">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                      <div v-if="Step4Loader" class="newLoader3"></div>
                    </v-card-actions>
                  </v-btn>
                </v-card-actions> -->
                <div class="TextVariant4 text-left mt-6"><span class="TextVariant5 cursorPointer text-decoration-underline" @click="loginStepper = 1">Back to Sign In</span></div>
              </v-window-item>
              <v-window-item :value="5">
                <div class="TextVariant1 text-left">Your password has been successfully updated.</div>
                <v-card flat color="blueColorVariant5" class="mt-3" height="42" :link="!Step1Loader ? true : false" @click="ValidateLoginMethod()">
                  <v-card-actions class="justify-space-between px-5">
                    <div class="mt-n2">Go to Login</div>
                    <div class="mt-n2"><v-icon>mdi-chevron-right</v-icon></div>
                  </v-card-actions>
                </v-card>
                <!-- <v-card-actions class="pa-0 ma-0 mt-8">
                  <v-btn class="text-capitalize BtnVariant1 FontSize14px" height="42" depressed block dark color="blueColorVariant5" @click="loginStepper = 1">
                    <v-card-actions class="pa-0 ma-0 justify-space-between">
                      <div>Go to Login</div>
                      <div><v-icon>mdi-chevron-right</v-icon></div>
                    </v-card-actions>
                  </v-btn>
                </v-card-actions> -->
              </v-window-item>
            </v-window>
          </v-card>
          <v-footer absolute app color="transparent" padless class="px-5">
            <v-card-text class="pa-0 ma-0" v-if="VuetifyObj.mdAndUp">
              <v-card-actions class="justify-space-between">
                <div class="TextVariant6">Copyright © {{ new Date().getFullYear() }} BankXchange</div>
                <div class="TextVariant6">info@bankxchange.com</div>
              </v-card-actions>
            </v-card-text>
            <v-row no-gutters v-if="VuetifyObj.smAndDown">
              <v-col cols="12" align="center">
                <div class="TextVariant6">info@bankxchange.com</div>
                <div class="TextVariant6 mt-3">Copyright © {{ new Date().getFullYear() }} BankXchange</div>
              </v-col>
            </v-row>
          </v-footer>
        </v-col>
      </v-row>
      <!-- </v-flex>
        </v-layout>
      </v-container> -->
    </v-card>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { Auth } from "aws-amplify";
import { LoginRestApi } from "@/mixins/RestAPIs/LoginRestApi.js";
import { useDisplay } from "vuetify";
import { initiateLocalCacheDB } from "@/db.js";
import dialogForTermsAndConditions from "@/components/Extras/TermsAndCondtions.vue"
export default {
  components: {
    Snackbar,
    dialogForTermsAndConditions,
  },
  mixins: [LoginRestApi,initiateLocalCacheDB],
  data: () => ({
    loginStepper: 1,
    user: null,

    Step1Loader: false,
    Step2Loader: false,
    Step3Loader: false,
    Step4Loader: false,
    show_new_password: false,
    dialogForTermsAndConditions:false,

    SnackBarComponent: {},
    Login: {
      EmailId: "",
      password: "",
      otp: "",
    },
    ForgotPassword: {
      EmailId: "",
      Activation_code: "",
      new_password: "",
    },
    VuetifyObj: {},
  }),
  watch: {
    "Login.EmailId"(val) {
      if (val) {
        this.Login.EmailId = val.toLowerCase();
      }
    },
    "ForgotPassword.EmailId"(val) {
      if (val) {
        this.ForgotPassword.EmailId = val.toLowerCase();
      }
    },
  },
  computed: {
    maskedEmail() {
      if (this.Login.EmailId) {
        const [prefix, domain] = this.Login.EmailId.split("@");
        const maskedPrefix = `${prefix.substring(0, 2)}******`;
        return `${maskedPrefix}@${domain}`;
      }
      return this.Login.EmailId;
    },
  },
 async  mounted() {
    this.VuetifyObj = useDisplay();
    // await this.resetDatabase();
    this.disableHistoryChanges();
  },
  methods: {

    disableHistoryChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },  
    GetUpdatedValueMethod(currentWidth) {
      if (currentWidth < 400 || currentWidth > 800) {
        return currentWidth - 400;
      } else if (currentWidth > 500 && currentWidth < 700) {
        return currentWidth - 100;
      } else if (currentWidth > 700 && currentWidth < 800) {
        return currentWidth - 300;
      } else if (currentWidth > 400 && currentWidth < 500) {
        return currentWidth - 50;
      } else {
        return currentWidth;
      }
    },
    async ValidateLoginMethod() {
      if (!this.Step1Loader) {
        const { valid } = await this.$refs.step1Form.validate();
        if (valid) {
          this.SignInMethod();
          this.Step1Loader = true;
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please fill mandatory fields.!",
          };
        }
      }
    },
    async SignInMethod() {
      try {
        this.Step1Loader = true;
        this.user = await Auth.signIn(this.Login.EmailId.toLowerCase().trim(), this.Login.password);
        this.user = await Auth.signIn(this.Login.EmailId.toLowerCase().trim());
        this.Step1Loader = false;
        this.loginStepper = 2;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "OTP Sent Successfully",
        };
      } catch (error) {
        this.Step1Loader = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.toString().split(":")[1],
        };
      }
    },
    async ValidateStep2OtpMethod() {
      const { valid } = await this.$refs.Step2Form.validate();
      if (valid) {
        this.StepTwoOTPValidateMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please enter OTP.!",
        };
      }
    },
    async StepTwoOTPValidateMethod() {
      try {
        this.Step2Loader = true;
        await Auth.sendCustomChallengeAnswer(this.user, this.Login.otp)
          .then(async (result) => {
            let LoginResult = await this.LoginRestApiMethod(result.attributes.sub);
            if (LoginResult.status === "Success") {
              this.ActivateMethod();
            }
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: err.message,
            };
            this.Step2Loader = false;
          });
        this.Step2Loader = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
        this.Step2Loader = false;
      }
    },
    async ValidateStep3Method() {
      const { valid } = await this.$refs.Step3Form.validate();
      if (valid) {
        this.SendOTPForgotPasswordMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please enter Email ID.!",
        };
      }
    },
    async SendOTPForgotPasswordMethod() {
      try {
        this.Step3Loader = true;
        Auth.forgotPassword(this.ForgotPassword.EmailId)
          .then(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "OTP Sent To Registered Email",
            };
            this.loginStepper = 4;
            this.Step3Loader = false;
          })
          .catch((err) => {
            this.Step3Loader = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: err.toString().split(":")[1],
            };
          });
      } catch (error) {
        this.Step3Loader = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.toString().split(":")[1],
        };
      }
    },
    async ValidateStep4Method() {
      const { valid } = await this.$refs.step4Form.validate();
      if (valid) {
        this.ResetPasswordMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please enter Email ID.!",
        };
      }
    },
    async ResetPasswordMethod() {
      try {
        this.Step4Loader = true;
        Auth.forgotPasswordSubmit(this.ForgotPassword.EmailId, this.ForgotPassword.Activation_code, this.ForgotPassword.new_password)
          .then(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "Password Successfully Changed ",
            };
            this.Step4Loader = false;
            this.$refs.step4Form.reset();

            this.loginStepper = 5;
          })
          .catch((err) => {
            this.Step4Loader = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: err.toString().split(":")[1],
            };
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.toString().split(":")[1],
        };
      }
    },
    ActivateMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In!!",
      };
      this.$store.commit("SET_USEREMAIL", this.Login.EmailId);
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
  },
};
</script>


