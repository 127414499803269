/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserStatus = /* GraphQL */ `
  query GetUserStatus {
    GetUserStatus
  }
`;
export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: commonAPIsInput) {
    GetCurrentUserDetails(input: $input) {
      status
      data {
        items {
          user_created_on
          user_first_name
          user_last_name
          user_profile_pic_url
          linkedin_profile_url
          executive_email_id
          preference_bankxchange_chat
          preference_office_contact_number
          bank_name
          user_email_id
          user_contact_number
          preference_business_email_id
          address_line_1
          address_line_2
          city
          state
          zipcode
          is_impersonated_user
          role_id
          preference_user_contact_number
          user_id
          office_contact_number
          bank_id
          executive_first_name
          executive_last_name
          user_status
          bank_details {
            bank_state
            internal_bank_name
            contact_person_email_id
            bank_address
            bank_country
            contact_person_role_id
            bank_created_on
            bank_zipcode
            bank_status
            bank_id
            contact_person_user_name
            bank_name
            no_of_users
            contact_person_contact_number
            bank_city
            bank_primary_regulator
            bank_no_of_branches
            bank_website
            bank_state_footprints
            bank_hq_state
            bank_total_assets
            bank_stock_ticker
          }
          role_details {
            role_id
            internal_role_name
            no_of_insights
            no_of_users
            role_created_on
            role_description
            role_name
            role_status
          }
          s3_details {
            access_key
            bucket_name
            secret_access_key
            region
          }
          community_details {
            no_of_users
          }
          version_details {
            version_title
            version_publised_on
            force_upgrade_version
            version_number
          }
        }
      }
    }
  }
`;
export const listInsight = /* GraphQL */ `
  query ListInsight($input: ListInsightInput) {
    ListInsight(input: $input)
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories($input: commonAPIsInput) {
    ListCategories(input: $input)
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources($input: ListResourceInput) {
    ListResources(input: $input)
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents($input: ListEventsInput) {
    ListEvents(input: $input)
  }
`;
export const listTrendingCommunities = /* GraphQL */ `
  query ListTrendingCommunities($input: ListTrendingCommunitiesInput) {
    ListTrendingCommunities(input: $input)
  }
`;
export const listMyCommunities = /* GraphQL */ `
  query ListMyCommunities($input: ListMyCommunitiesInput) {
    ListMyCommunities(input: $input) {
      status
      data {
        items {
          community_id
          community_name
          community_type
          community_created_on
          community_description
          community_created_by
          community_status
          is_default_community
          last_message_details {
            community_id
            message_text
            messaged_by_id
            messaged_by_user_details {
              user_created_on
              user_first_name
              user_last_name
              user_profile_pic_url
              linkedin_profile_url
              executive_email_id
              preference_bankxchange_chat
              preference_office_contact_number
              bank_name
              user_email_id
              user_contact_number
              preference_business_email_id
              address_line_1
              address_line_2
              city
              state
              zipcode
              is_impersonated_user
              role_id
              preference_user_contact_number
              user_id
              office_contact_number
              bank_id
              executive_first_name
              executive_last_name
              user_status
              bank_details {
                bank_state
                internal_bank_name
                contact_person_email_id
                bank_address
                bank_country
                contact_person_role_id
                bank_created_on
                bank_zipcode
                bank_status
                bank_id
                contact_person_user_name
                bank_name
                no_of_users
                contact_person_contact_number
                bank_city
                bank_primary_regulator
                bank_no_of_branches
                bank_website
                bank_state_footprints
                bank_hq_state
                bank_total_assets
                bank_stock_ticker
              }
              role_details {
                role_id
                internal_role_name
                no_of_insights
                no_of_users
                role_created_on
                role_description
                role_name
                role_status
              }
              s3_details {
                access_key
                bucket_name
                secret_access_key
                region
              }
              community_details {
                no_of_users
              }
              version_details {
                version_title
                version_publised_on
                force_upgrade_version
                version_number
              }
            }
            message_id
            messaged_on
            message_type
            message_sent_type
            has_reply_message
            no_of_replies
            replied_to_message_id
            replied_for_message_type
            replied_for_message_text
            reactions {
              community_id
              reaction_id
              message_id
              reacted_time
              reacted_by_user_id
              reacted_by_user_first_name
              reacted_by_user_last_name
              reaction_type
            }
          }
          no_of_users
          community_user_ids
          community_users {
            user_created_on
            user_first_name
            user_last_name
            user_profile_pic_url
            linkedin_profile_url
            executive_email_id
            preference_bankxchange_chat
            preference_office_contact_number
            bank_name
            user_email_id
            user_contact_number
            preference_business_email_id
            address_line_1
            address_line_2
            city
            state
            zipcode
            is_impersonated_user
            role_id
            preference_user_contact_number
            user_id
            office_contact_number
            bank_id
            executive_first_name
            executive_last_name
            user_status
          }
        }
      }
    }
  }
`;
export const listOtherCommunities = /* GraphQL */ `
  query ListOtherCommunities($input: ListMyCommunitiesInput) {
    ListOtherCommunities(input: $input) {
      status
      data {
        items {
          community_id
          community_name
          community_type
          community_created_on
          community_description
          community_created_by
          community_status
          is_default_community
          last_message_details {
            community_id
            message_text
            messaged_by_id
            messaged_by_user_details {
              user_created_on
              user_first_name
              user_last_name
              user_profile_pic_url
              linkedin_profile_url
              executive_email_id
              preference_bankxchange_chat
              preference_office_contact_number
              bank_name
              user_email_id
              user_contact_number
              preference_business_email_id
              address_line_1
              address_line_2
              city
              state
              zipcode
              is_impersonated_user
              role_id
              preference_user_contact_number
              user_id
              office_contact_number
              bank_id
              executive_first_name
              executive_last_name
              user_status
              bank_details {
                bank_state
                internal_bank_name
                contact_person_email_id
                bank_address
                bank_country
                contact_person_role_id
                bank_created_on
                bank_zipcode
                bank_status
                bank_id
                contact_person_user_name
                bank_name
                no_of_users
                contact_person_contact_number
                bank_city
                bank_primary_regulator
                bank_no_of_branches
                bank_website
                bank_state_footprints
                bank_hq_state
                bank_total_assets
                bank_stock_ticker
              }
              role_details {
                role_id
                internal_role_name
                no_of_insights
                no_of_users
                role_created_on
                role_description
                role_name
                role_status
              }
              s3_details {
                access_key
                bucket_name
                secret_access_key
                region
              }
              community_details {
                no_of_users
              }
              version_details {
                version_title
                version_publised_on
                force_upgrade_version
                version_number
              }
            }
            message_id
            messaged_on
            message_type
            message_sent_type
            has_reply_message
            no_of_replies
            replied_to_message_id
            replied_for_message_type
            replied_for_message_text
            reactions {
              community_id
              reaction_id
              message_id
              reacted_time
              reacted_by_user_id
              reacted_by_user_first_name
              reacted_by_user_last_name
              reaction_type
            }
          }
          no_of_users
          community_user_ids
          community_users {
            user_created_on
            user_first_name
            user_last_name
            user_profile_pic_url
            linkedin_profile_url
            executive_email_id
            preference_bankxchange_chat
            preference_office_contact_number
            bank_name
            user_email_id
            user_contact_number
            preference_business_email_id
            address_line_1
            address_line_2
            city
            state
            zipcode
            is_impersonated_user
            role_id
            preference_user_contact_number
            user_id
            office_contact_number
            bank_id
            executive_first_name
            executive_last_name
            user_status
          }
        }
      }
    }
  }
`;
export const listCommunityMembers = /* GraphQL */ `
  query ListCommunityMembers($input: ListCommunityMembersInput) {
    ListCommunityMembers(input: $input)
  }
`;
export const listCommunityUsers = /* GraphQL */ `
  query ListCommunityUsers($input: ListCommunityMembersInput) {
    ListCommunityUsers(input: $input) {
      status
      data {
        items {
          user_created_on
          user_first_name
          user_last_name
          user_profile_pic_url
          linkedin_profile_url
          executive_email_id
          preference_bankxchange_chat
          preference_office_contact_number
          bank_name
          user_email_id
          user_contact_number
          preference_business_email_id
          address_line_1
          address_line_2
          city
          state
          zipcode
          is_impersonated_user
          role_id
          preference_user_contact_number
          user_id
          office_contact_number
          bank_id
          executive_first_name
          executive_last_name
          user_status
          bank_details {
            bank_state
            internal_bank_name
            contact_person_email_id
            bank_address
            bank_country
            contact_person_role_id
            bank_created_on
            bank_zipcode
            bank_status
            bank_id
            contact_person_user_name
            bank_name
            no_of_users
            contact_person_contact_number
            bank_city
            bank_primary_regulator
            bank_no_of_branches
            bank_website
            bank_state_footprints
            bank_hq_state
            bank_total_assets
            bank_stock_ticker
          }
          role_details {
            role_id
            internal_role_name
            no_of_insights
            no_of_users
            role_created_on
            role_description
            role_name
            role_status
          }
          s3_details {
            access_key
            bucket_name
            secret_access_key
            region
          }
          community_details {
            no_of_users
          }
          version_details {
            version_title
            version_publised_on
            force_upgrade_version
            version_number
          }
        }
        next_token
      }
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests($input: ListRequestsInput) {
    ListRequests(input: $input)
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles($input: commonAPIsInput) {
    ListRoles(input: $input)
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles($input: commonAPIsInput) {
    ListUserRoles(input: $input)
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($input: ListMessageInput) {
    ListMessages(input: $input) {
      items {
        community_id
        message_text
        messaged_by_id
        messaged_by_user_details {
          user_created_on
          user_first_name
          user_last_name
          user_profile_pic_url
          linkedin_profile_url
          executive_email_id
          preference_bankxchange_chat
          preference_office_contact_number
          bank_name
          user_email_id
          user_contact_number
          preference_business_email_id
          address_line_1
          address_line_2
          city
          state
          zipcode
          is_impersonated_user
          role_id
          preference_user_contact_number
          user_id
          office_contact_number
          bank_id
          executive_first_name
          executive_last_name
          user_status
          bank_details {
            bank_state
            internal_bank_name
            contact_person_email_id
            bank_address
            bank_country
            contact_person_role_id
            bank_created_on
            bank_zipcode
            bank_status
            bank_id
            contact_person_user_name
            bank_name
            no_of_users
            contact_person_contact_number
            bank_city
            bank_primary_regulator
            bank_no_of_branches
            bank_website
            bank_state_footprints
            bank_hq_state
            bank_total_assets
            bank_stock_ticker
          }
          role_details {
            role_id
            internal_role_name
            no_of_insights
            no_of_users
            role_created_on
            role_description
            role_name
            role_status
          }
          s3_details {
            access_key
            bucket_name
            secret_access_key
            region
          }
          community_details {
            no_of_users
          }
          version_details {
            version_title
            version_publised_on
            force_upgrade_version
            version_number
          }
        }
        message_id
        messaged_on
        message_type
        message_sent_type
        has_reply_message
        no_of_replies
        replied_to_message_id
        replied_for_message_type
        replied_for_message_text
        reactions {
          community_id
          reaction_id
          message_id
          reacted_time
          reacted_by_user_id
          reacted_by_user_first_name
          reacted_by_user_last_name
          reaction_type
        }
      }
      next_token
    }
  }
`;
export const listSurveyOrCommunitySurvey = /* GraphQL */ `
  query ListSurveyOrCommunitySurvey($input: ListSurveyOrCommunitySurveyInput) {
    ListSurveyOrCommunitySurvey(input: $input)
  }
`;
export const getRepliedMessages = /* GraphQL */ `
  query GetRepliedMessages($input: GetRepliedMessagesInput) {
    GetRepliedMessages(input: $input)
  }
`;
export const getEventsAttachments = /* GraphQL */ `
  query GetEventsAttachments($input: GetEventAttachmentsInput) {
    GetEventsAttachments(input: $input)
  }
`;
export const getMetaData = /* GraphQL */ `
  query GetMetaData($input: GetMetaDataInput) {
    GetMetaData(input: $input)
  }
`;
export const listDocumentVaultCategories = /* GraphQL */ `
  query ListDocumentVaultCategories($input: ListDocumentCategoriesInput) {
    ListDocumentVaultCategories(input: $input)
  }
`;
export const listDocumentVaultDocument = /* GraphQL */ `
  query ListDocumentVaultDocument($input: ListDocumentVaultDocumentInput) {
    ListDocumentVaultDocument(input: $input)
  }
`;
export const listCommunityCorner = /* GraphQL */ `
  query ListCommunityCorner($input: ListCommunityCornerInput) {
    ListCommunityCorner(input: $input)
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews($input: ListNewsInput) {
    ListNews(input: $input)
  }
`;
export const getPresignedURL = /* GraphQL */ `
  query GetPresignedURL($input: GetPresignedURLInput) {
    GetPresignedURL(input: $input)
  }
`;
export const listLatestActivity = /* GraphQL */ `
  query ListLatestActivity($input: ListLatestActivityInput) {
    ListLatestActivity(input: $input)
  }
`;
